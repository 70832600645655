import chroma from "chroma-js";
import useAuth from "./useAuth";

interface TenantColors {
  primary: string;
  primaryTransparent: string;
  primaryForeground: string;
}

export const useTenantColor = (): TenantColors => {
  const { school } = useAuth()

  const tenantPrimaryColor = school?.primaryColor ?? "#0C78F1"; // replace with tenant color from api
  const primaryColor = chroma(tenantPrimaryColor);

  const primaryTransparent = primaryColor.alpha(0.1);

  const primaryForeground =
    chroma(primaryColor).get("lab.l") < 80 ? "#FFFFFF" : "#000000";

  return {
    primary: primaryColor.css("hsl"),
    primaryTransparent: primaryTransparent.css("hsl"),
    primaryForeground,
  };
};
