import { createLesson, updateLesson } from "@/api/common";
import {
  CustomEditor,
  CustomInput,
  Dropzone,
  SubjectAndClassSelect,
} from "@/components";
import CustomSelect from "@/components/CustomSelect";
import { Button } from "@/components/ui/button";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { showSuccessToast } from "@/lib/toast";
import { convertToEditorState } from "@/lib/utils";
import { IClass } from "@/types/class.types";
import { ILesson } from "@/types/lesson.types";
import { ISubject } from "@/types/subject.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import * as Yup from "yup";

type Props = {
  closeModal: () => void;
  lesson?: ILesson;
};

const AddSingleLesson = ({ closeModal, lesson }: Props) => {
  const { currentTermId } = useMainLayoutContext();
  const queryClient = useQueryClient();

  const initialValues = {
    id: lesson?._id ?? "",
    topic: lesson?.topic ?? "",
    subject: (lesson?.subject as ISubject)?._id ?? "",
    class: (lesson?.class as IClass)?._id ?? "",
    week: lesson?.week ?? "",
    content: lesson?.content ?? "",
    attachments: [],
    video: null,
    term: lesson?.term ?? currentTermId,
  };
  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("This field is required"),
    week: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
  });

  const { mutate, isLoading } = useMutation(
    !!lesson ? updateLesson : createLesson,
    {
      onSuccess: ({ message }) => {
        showSuccessToast(message);
        queryClient.refetchQueries({
          queryKey: ["lesson"],
          type: "active",
        });
        closeModal();
      },
    },
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-5 pt-8">
          <CustomInput
            label="Lesson Topic"
            value={values.topic}
            onChange={handleChange}
            placeholder="Enter lesson title"
            name="topic"
          />
          <div className="grid gap-5 @[350px]/sheet:grid-cols-2">
            <SubjectAndClassSelect
              subjectValue={values.subject}
              subjectOnChange={(val) => setFieldValue("subject", val)}
              classOnChange={(val) => setFieldValue("class", val)}
              key={`${values.subject}-${values.class}`}
              classValue={values.class}
              edit
            />
            <CustomSelect
              options={Array(15)
                .fill("")
                .map((_, index) => ({
                  label: (index + 1).toString(),
                  value: (index + 1).toString(),
                }))}
              value={values.week}
              onChange={(val) => setFieldValue("week", val)}
              placeholder="Select week"
              name="week"
              label="Week"
            />
          </div>
          <CustomEditor
            label="Lesson Notes"
            setState={(editorState) => setFieldValue("content", editorState)}
            defaultEditorState={convertToEditorState(values.content)}
          />
          <Dropzone name="attachments" multiple />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddSingleLesson;
