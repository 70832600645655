import { gradeGroupAssignmentSubmission } from "@/api/common";
import { Attachment, CustomEditor, CustomInput } from "@/components";
import { Button } from "@/components/ui/button";
import { showSuccessToast } from "@/lib/toast";
import { IAssignmentSubmission } from "@/types/assignments.types";
import { IStudent } from "@/types/user.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MathJax } from "better-react-mathjax";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

type Props = {
  submissions: IAssignmentSubmission[];
  closeModal: () => void;
};

const StudentInfo = ({ student }: { student: Partial<IStudent> }) => {
  return (
    <div>
      <p className="font-medium text-[#3A4250]">
        {(student.student as IStudent)?.firstname}{" "}
        {(student.student as IStudent)?.lastname}
      </p>
      <p className="text-sm text-[#495364]">
        {(student.student as IStudent)?.admissionNumber}
      </p>
    </div>
  );
};

const GroupAssignmentSubmission = ({ submissions, closeModal }: Props) => {
  const queryClient = useQueryClient();

  const initialValues = {
    grade: "",
    remark: "",
    submissions: submissions?.map((sub) => sub._id),
  };
  const validationSchema = Yup.object().shape({
    grade: Yup.number()
      .min(0, "Minimum score is zero")
      .max(100, "Maximum score is zero")
      .required("This field is required"),
    remark: Yup.string(),
    submissions: Yup.array()
      .of(Yup.string().required())
      .min(1, "At least one student must be provided"),
  });

  const { mutate, isLoading } = useMutation(gradeGroupAssignmentSubmission, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({ queryKey: ["assignment-submissions"] });
      closeModal();
    },
  });

  const submissionDetails = submissions?.at(1);
  const attachments = submissionDetails?.attachments;
  const students = submissions?.map((sub) => sub.student as IStudent);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-6">
          <div className="grid gap-1">
            <p className="text-xs font-medium uppercase text-[#737D8F]">
              Group Members
            </p>
            {students?.map((student, index) => (
              <StudentInfo key={index} student={student} />
            ))}
          </div>
          {!!submissionDetails?.content && (
            <div className="grid gap-1">
              <p className="text-xs font-medium uppercase text-[#737D8F]">
                Answer
              </p>
              <MathJax>
                <p
                  className="text-sm font-medium text-[#2C323C]"
                  dangerouslySetInnerHTML={{
                    __html: submissionDetails?.content,
                  }}
                />
              </MathJax>
            </div>
          )}
          <div className="flex flex-wrap gap-5">
            {attachments?.map((attachment, index) => (
              <Attachment key={index} attachment={attachment} />
            ))}
          </div>
          <CustomInput
            label="Grade"
            id="grade"
            value={values.grade}
            onChange={handleChange}
            type="number"
            inputMode="numeric"
            placeholder="Enter grade"
            name="grade"
            min={0}
            step={0.01}
            max={100}
          />
          <CustomEditor
            label="Remarks"
            setState={(editorState) => setFieldValue("remark", editorState)}
          />
          <ErrorMessage
            name="submissions"
            component="div"
            className="block mt-1 text-xs text-destructive"
          />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Grade
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default GroupAssignmentSubmission;
