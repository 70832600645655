import { lazy } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { Toaster } from "./components/ui/sonner";
import AuthContextProvider from "./context/AuthContext";
import { useTenantColor } from "./hooks/useTenantColor";

// pages
import {
  ForgotPasswordPage,
  HomePage,
  StudentsPage,
  LessonsDetailsPage,
  LessonsPage,
  LoginPage,
  InstructorsPage,
  AssignmentsPage,
  ProfilePage,
  SettingsPage,
  AnnouncementPage,
  LessonsBankPage,
  LessonBankDetailsPage,
  AssignmentSubmissions,
  LoginWithCodePage,
  SubjectsPage,
  StudentLessonPage,
  StudentAssignmentPage,
  StudentsAnnouncementPage,
  LiveClassPage,
} from "./pages";
import SubjectLessonsPage from "./pages/SubjectLessonsPage";
import TokenAuth from "./pages/TokenAuth";

// layouts
const MainLayout = lazy(() => import("./components/layouts/MainLayout"));
const AuthLayout = lazy(() => import("./components/layouts/AuthLayout"));

const AuthProvider = () => {
  return (
    <AuthContextProvider>
      <ColorProvider />
    </AuthContextProvider>
  );
};

const ColorProvider = () => {
  const { primary, primaryTransparent, primaryForeground } = useTenantColor();

  return (
    <div
      style={
        {
          "--color-primary": primary,
          "--color-primary-transparent": primaryTransparent,
          "--color-primary-foreground": primaryForeground,
        } as React.CSSProperties
      }
    >
      <Outlet />
      <Toaster position="top-right" />
    </div>
  );
};

const Router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider />,
    children: [
      { path: "token-auth", element: <TokenAuth /> },
      {
        path: "admin",
        children: [
          {
            path: "",
            element: <MainLayout />,
            children: [
              { index: true, element: <HomePage /> },
              {
                path: "lessons",
                handle: { crumb: "Lesson" },
                children: [
                  {
                    index: true,
                    element: <LessonsPage />,
                  },
                  {
                    path: ":lessonId",
                    handle: { crumb: "View Lesson" },
                    children: [
                      {
                        index: true,
                        element: <LessonsDetailsPage />,
                      },
                      {
                        handle: { crumb: "Assignment Submission" },
                        path: "assignment/:assignmentId",
                        element: <AssignmentSubmissions />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "assignments",
                handle: { crumb: "Assignment" },
                children: [
                  {
                    index: true,
                    element: <AssignmentsPage />,
                  },
                  {
                    path: ":assignmentId",
                    handle: { crumb: "Assignment Submissions" },
                    element: <AssignmentSubmissions />,
                  },
                ],
              },
              { path: "announcements", element: <AnnouncementPage /> },
              {
                path: "lesson-bank",
                handle: { crumb: "Lesson Bank" },
                children: [
                  {
                    index: true,
                    element: <LessonsBankPage />,
                  },
                  {
                    path: ":lessonId",
                    handle: { crumb: "View Lesson" },
                    element: <LessonBankDetailsPage />,
                  },
                ],
              },
              { path: "students", element: <StudentsPage /> },
              { path: "instructors", element: <InstructorsPage /> },
              { path: "profile", element: <ProfilePage /> },
              { path: "settings", element: <SettingsPage /> },
              {
                path: "*",
                element: (
                  <div>
                    <b className="text-destructive">404:</b> Not found
                  </div>
                ),
              },
            ],
          },
          {
            path: "",
            element: <AuthLayout />,
            children: [
              {
                path: "login",
                children: [
                  { index: true, element: <LoginPage /> },
                  { path: ":code", element: <LoginWithCodePage /> },
                ],
              },
              {
                path: "forgot-password",
                element: <ForgotPasswordPage />,
              },
            ],
          },
        ],
      },
      {
        path: "teacher",
        children: [
          {
            path: "",
            element: <MainLayout />,
            children: [
              { index: true, element: <HomePage /> },
              {
                path: "lessons",
                handle: { crumb: "Lesson" },
                children: [
                  {
                    index: true,
                    element: <LessonsPage />,
                  },
                  {
                    path: ":lessonId",
                    handle: { crumb: "View Lesson" },
                    children: [
                      {
                        index: true,
                        element: <LessonsDetailsPage />,
                      },
                      {
                        handle: { crumb: "Assignment Submission" },
                        path: "assignment/:assignmentId",
                        element: <AssignmentSubmissions />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "assignments",
                handle: { crumb: "Assignment" },
                children: [
                  {
                    index: true,
                    element: <AssignmentsPage />,
                  },
                  {
                    path: ":assignmentId",
                    handle: { crumb: "Assignment Submissions" },
                    element: <AssignmentSubmissions />,
                  },
                ],
              },
              { path: "announcements", element: <AnnouncementPage /> },
              {
                path: "lesson-bank",
                handle: { crumb: "Lesson Bank" },
                children: [
                  {
                    index: true,
                    element: <LessonsBankPage />,
                  },
                  {
                    path: ":lessonId",
                    handle: { crumb: "View Lesson" },
                    element: <LessonBankDetailsPage />,
                  },
                ],
              },
              { path: "students", element: <StudentsPage /> },
              { path: "instructors", element: <InstructorsPage /> },
              { path: "profile", element: <ProfilePage /> },
              { path: "live-class", element: <LiveClassPage /> },
              {
                path: "subjects",
                handle: { crumb: "Subjects" },
                children: [
                  { index: true, element: <SubjectsPage /> },
                  {
                    path: ":subjectId",
                    handle: { crumb: "View Lessons" },
                    element: <SubjectLessonsPage />,
                  },
                ],
              },
              {
                path: "*",
                element: (
                  <div>
                    <b className="text-destructive">404:</b> Not found
                  </div>
                ),
              },
            ],
          },
          {
            path: "",
            element: <AuthLayout />,
            children: [
              {
                path: "login",
                children: [
                  { index: true, element: <LoginPage /> },
                  { path: ":code", element: <LoginWithCodePage /> },
                ],
              },
              {
                path: "forgot-password",
                element: <ForgotPasswordPage />,
              },
            ],
          },
        ],
      },
      {
        path: "",
        children: [
          {
            path: "",
            element: <MainLayout />,
            children: [
              { index: true, element: <HomePage /> },
              {
                path: "lessons",
                children: [
                  { index: true, element: <StudentLessonPage /> },
                  { path: ":lessonId", element: <LessonsDetailsPage /> },
                ],
              },
              { path: "assignments", element: <StudentAssignmentPage /> },
              { path: "announcements", element: <StudentsAnnouncementPage /> },
              { path: "profile", element: <ProfilePage /> },
              {
                path: "*",
                element: (
                  <div>
                    <b className="text-destructive">404:</b> Not found
                  </div>
                ),
              },
            ],
          },
          {
            path: "",
            element: <AuthLayout />,
            children: [
              {
                path: "login",
                children: [
                  { index: true, element: <LoginPage /> },
                  { path: ":code", element: <LoginWithCodePage /> },
                ],
              },
              {
                path: "forgot-password",
                element: <ForgotPasswordPage />,
              },
            ],
          },
          {
            path: "*",
            element: (
              <div>
                <b className="text-destructive">404:</b> Not found
              </div>
            ),
          },
        ],
      },
    ],
  },
]);

const RouterComponent = () => {
  return <RouterProvider router={Router} />;
};

export default RouterComponent;
