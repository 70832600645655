import * as Yup from "yup";
import { Formik } from "formik";
import { CustomSelect, Dropzone } from "@/components";
import { Button, buttonVariants } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showSuccessToast } from "@/lib/toast";
import { addBulkStudents } from "@/api/students";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { Loader2Icon } from "lucide-react";
import { useLayout } from "@/hooks/useLayout";
import { IClass } from "@/types/class.types";

type AddMultipleStudentProps = { onClose?: () => void };

const AddMultipleStudent = ({ onClose }: AddMultipleStudentProps) => {
  const layout = useLayout();
  const queryClient = useQueryClient();
  const { classes, currentTermId, allocations } = useMainLayoutContext();
  const allClassesOptions =
    classes?.map((classItem) => ({
      value: classItem._id,
      label: classItem.name,
    })) || [];

  const allocationOptions =
    allocations?.map((allocation) => ({
      value: (allocation.class as IClass)._id,
      label: (allocation.class as IClass).name,
    })) || [];

  const initialValues = {
    classId: "",
    termId: currentTermId || "",
    students: null,
  };

  const validationSchema = Yup.object().shape({
    classId: Yup.string().required("This field is required"),
    termId: Yup.string(),
    students: Yup.mixed()
      .required("File is required")
      .test("students", "Only Excel files are allowed", (value) => {
        if (value) {
          const file = value as File;
          const supportedFormats = ["xls", "xlsx"];

          return supportedFormats.includes(
            file?.name?.split(".").pop()!.toLowerCase(),
          );
        }
        return true;
      }),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: addBulkStudents,
    mutationKey: ["students", "bulk"],
    onSuccess: (data) => {
      showSuccessToast(data.message || "Students added", {
        closeButton: true,
      });

      if (
        data.data?.studentsNotAdded &&
        data.data?.studentsNotAdded.length > 0
      ) {
        showSuccessToast(
          `Students not added: ${data.data?.studentsNotAdded.length}`,
          {
            description: (
              <div>
                <h3 className="mb-2 text-sm font-semibold">
                  The following students could not be added:
                </h3>
                <ul className="space-y-0.5">
                  {data.data.studentsNotAdded.map((t, i) => (
                    <li className="list-disc text-sm" key={i}>
                      {t?.email}
                    </li>
                  ))}
                </ul>
              </div>
            ),
            closeButton: true,
          },
        );
      }
      queryClient.invalidateQueries({ queryKey: ["students"] });

      onClose?.();
    },
  });

  function handleUpload(values) {
    mutate(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpload}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, errors, setFieldValue }) => (
        <form
          className="flex flex-col justify-between gap-4 pb-8"
          onSubmit={handleSubmit}
        >
          <Link
            to="/sample-students.xlsx"
            target="_blank"
            download
            className="w-fit text-sm font-semibold text-primary"
          >
            Download Sample Excel File
          </Link>
          <CustomSelect
            options={layout === "admin" ? allClassesOptions : allocationOptions}
            value={values.classId}
            name="classId"
            onChange={(val) => setFieldValue("classId", val)}
            placeholder="Select Class"
            label="Class"
          />
          <Dropzone name="students" />

          <div className="flex w-full items-center justify-end gap-2">
            <DialogClose
              type="button"
              className={buttonVariants({
                variant: "outline",
                className: "h-8.5",
              })}
            >
              Cancel
            </DialogClose>
            <Button type="submit" className="h-8.5 min-w-[100px]">
              {isLoading ? <Loader2Icon className="animate-spin" /> : "Save"}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddMultipleStudent;
