import { useLayout } from "@/hooks/useLayout";
import { cn } from "@/lib/utils";
import { IAssignmentGroup } from "@/types/assignments.types";
import CustomDataTable from "./CustomDataTable";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";

type AssignmentGroupsType = {
  groups: IAssignmentGroup[] | null;
};

const AssignmentGroups = ({ groups }: AssignmentGroupsType) => {
  const layout = useLayout();

  if (layout === "student") return null;

  if (!groups?.length) return null;

  return (
    <div className="mt-6">
      <p className="mb-2 font-medium">Groups</p>
      <Accordion type="multiple" className={cn("flex flex-col gap-3")}>
        {groups?.map((group) => (
          <AssignmentGroup key={group._id} group={group} />
        ))}
      </Accordion>
    </div>
  );
};

const columns = [
  { name: "S/N", selector: (_, index = 0) => index + 1, width: "90px" },
  { name: "Student Name", selector: (row) => row.name, grow: 2.5 },
  {
    name: "Gender",
    cell: (row) => <span className="capitalize">{row.gender}</span>,
  },
];

const AssignmentGroup = ({ group }: { group: IAssignmentGroup }) => {
  return (
    <AccordionItem value={`item-${group._id}`} className="h-fit">
      <AccordionTrigger>{group.name}</AccordionTrigger>
      <AccordionContent>
        <CustomDataTable
          data={group.students}
          columns={columns}
          paginationServer={false}
          emptyStateMessage="No students"
        />
      </AccordionContent>
    </AccordionItem>
  );
};

export default AssignmentGroups;
