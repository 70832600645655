import { updateAutoApprovalStatus } from "@/api/admin";
import { CustomSelect } from "@/components";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import useAuth from "@/hooks/useAuth";
import { showSuccessToast } from "@/lib/toast";
import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import * as Yup from "yup"

const validationSchema = Yup.object().shape({
   automaticApproval: Yup.string().oneOf(["true", "false"]).required("This field is required")
})

const LessonSettings = () => {
   const { setSchool, school } = useAuth()

   const initialValues = {
      automaticApproval: school?.automaticallyApproveLesson ? "true" : "false"
   };
   const { mutate, isLoading } = useMutation(updateAutoApprovalStatus, {
      onSuccess: ({ message, data }) => {
         showSuccessToast(message);
         setSchool(data)
      }
   })

   return (
      <Card className="mt-13 rounded-[20px] border-transparent px-4 py-5 md:px-5 md:py-6 lg:px-10 lg:py-11">
         <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={({ automaticApproval }) => mutate({ automaticApproval: automaticApproval === "true" })}>
            {({ values, setFieldValue, handleSubmit }) => {
               return (
                  <form onSubmit={handleSubmit}>
                     <div className="space-y-8">
                        <CustomSelect
                           options={[
                              { label: "Teacher", value: "true" },
                              { label: "Admin", value: "false" },
                           ]}
                           value={values.automaticApproval}
                           onChange={(val) => setFieldValue("automaticApproval", val)}
                           label="Course approver"
                           placeholder="Select staff"
                        />

                        <div className="flex h-full py-0.5 md:items-end">
                           <Button disabled={isLoading} className="rounded-lg ">
                              Update
                           </Button>
                        </div>
                     </div>
                  </form>
               );
            }}
         </Formik>
      </Card>
   );
};

export default LessonSettings;
