import { IAllocation } from "@/types/allocations.types";
import { IStudent } from "@/types/user.types";
import { ApiResponse, PaginatedResponse } from "@/types/api.types";
import axios from "axios";
import {
  onReqFulfilled,
  onReqRejected,
  onResFulfilled,
  onResRejected,
} from "./helpers";
import { ILesson } from "@/types/lesson.types";

const teacherAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASEURL}/teacher`,
});

teacherAPI.interceptors.request.use(onReqFulfilled, onReqRejected);
teacherAPI.interceptors.response.use(onResFulfilled, onResRejected);

export const getSubjectAllocations = (): Promise<
  ApiResponse<PaginatedResponse<IAllocation[]>>
> => teacherAPI.get("/subjects");

export const getTeacherDashboard = async (
  params: Record<string, any>,
): Promise<
  ApiResponse<{
    allocatedSubjectCount: number;
    uploadLessonCount: number;
    approvedLessons: ILesson[];
  }>
> => teacherAPI.get("/dashboard", { params });

export const getTeacherStudents = async (
  params: Record<string, any>,
): Promise<ApiResponse<PaginatedResponse<IStudent[]>>> =>
  teacherAPI.get("/students", { params });

export const getTeacherSubjects = async (
  params: Record<string, any>,
): Promise<ApiResponse<PaginatedResponse<IAllocation[]>>> =>
  teacherAPI.get("/subjects", { params });

export const getTeacherLessons = async (
  params: Record<string, any>,
): Promise<ApiResponse<PaginatedResponse<ILesson[]>>> =>
  teacherAPI.get("/lessons", { params });
