export const getFromSessionStorage = (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const setToSessionStorage = (key: string, value: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error saving "${key}":`, error);
  }
};

export const removeFromSessionStorage = (...keys: string[]) => keys.forEach(key => sessionStorage.removeItem(key))

export const clearSessionStorage = () => {
  try {
    sessionStorage.clear();
  } catch (error) {
    console.error(`Error clearing session storage:`, error);
  }
};

// Local storage

export const getFromLocalStorage = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const setToLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error saving "${key}":`, error);
  }
};

export const removeFromLocalStorage = (...keys: string[]) => keys.forEach(key => localStorage.removeItem(key));

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error(`Error clearing local storage:`, error);
  }
};
