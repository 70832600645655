import { IAssignment } from "@/types/assignments.types";
import { ISubject } from "@/types/subject.types";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { UpRightArrowIcon } from "@/assets/icons";

type AssignmentCardProps = { assignment: IAssignment; className?: string };

const AssignmentCard = ({ assignment, className }: AssignmentCardProps) => {
  return (
    <Link
      to={`/assignments?assignmentId=${assignment._id}`}
      className={cn(
        "flex items-center justify-between rounded-2xl bg-white px-5 py-6",
        className,
      )}
    >
      <div>
        <p className="mb-1 text-sm font-medium uppercase tracking-[2.18px] text-[#3A4250]">
          {(assignment.subject as ISubject).title}
        </p>
        <p className="font-semibold text-[#3A4250]">{assignment.title}</p>
      </div>
      <UpRightArrowIcon className="text-primary" />
    </Link>
  );
};

export default AssignmentCard;
