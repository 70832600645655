import { RightIcon } from "@/assets/icons";
import { Fragment } from "react";
import { Link, useLocation, useMatches } from "react-router-dom";

const BreadCrumbs = () => {
  const location = useLocation();
  let matches = useMatches();

  const crumbs = matches.filter((match) =>
    Boolean((match.handle as any)?.crumb),
  );

  const length = crumbs?.length;

  return (
    <ol className="mb-5 flex items-center gap-[2px] text-sm font-medium text-[#66717F]">
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          <li
            className={
              crumb.pathname === location.pathname ? "text-primary" : ""
            }
          >
            <Link to={crumb.pathname}>{(crumb.handle as any).crumb}</Link>
          </li>
          {index !== length - 1 && (
            <li className="">
              <RightIcon className="size-5 text-[#66717F]" />
            </li>
          )}
        </Fragment>
      ))}
    </ol>
  );
};

export default BreadCrumbs;
