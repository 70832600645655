import useLayoutNavigate from "@/hooks/useLayoutNavigate";
import { cn } from "@/lib/utils";
import { ILesson } from "@/types/lesson.types";
import { useParams } from "react-router-dom";
import NoteTag from "./NoteTag";
import VideoTag from "./VideoTag";

type Props = {
  lesson: ILesson;
};

const LessonButton = ({ lesson }: Props) => {
  const { lessonId } = useParams()
  const navigate = useLayoutNavigate();

  return (
    <button
      className={cn("w-full text-left hover:bg-[#EEF7FF]", lessonId === lesson._id && "bg-[#EEF7FF]")}
      title={lesson.topic}
      onClick={() => navigate(`/lessons/${lesson._id}`)}
    >
      <p className="mb-1 line-clamp-1 font-sans font-semibold text-[#495364]">
        {lesson.topic}
      </p>
      <div className="flex items-center gap-2">
        {!!lesson.videoUrl && <VideoTag />}
        {!!lesson.content && <NoteTag />}
      </div>
    </button>
  );
};

export default LessonButton;
