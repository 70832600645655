export const subjectOptions = [
   { value: "2021/2022-3", label: "Mathematics" },
   { value: "2021/2022-2", label: "Biology" },
   { value: "2021/2022-1", label: "Physics" },
];
export const classOptions = [
   { value: "2021/2022-3", label: "JSS 1" },
   { value: "2021/2022-2", label: "JSS 2" },
   { value: "2021/2022-1", label: "JSS 3" },
];
export const studentOptions = [
   { id: 1, firstname: 'John', lastname: 'Doe' },
   { id: 2, firstname: 'Jane', lastname: 'Smith' },
   { id: 3, firstname: 'Michael', lastname: 'Johnson' },
   { id: 4, firstname: 'Emily', lastname: 'Davis' },
   { id: 5, firstname: 'Chris', lastname: 'Brown' },
   { id: 6, firstname: 'Sarah', lastname: 'Wilson' },
   { id: 7, firstname: 'David', lastname: 'Garcia' },
   { id: 8, firstname: 'Jessica', lastname: 'Martinez' },
   { id: 9, firstname: 'Daniel', lastname: 'Lopez' },
   { id: 10, firstname: 'Samantha', lastname: 'Gonzalez' },
   { id: 11, firstname: 'Matthew', lastname: 'Perez' },
   { id: 12, firstname: 'Ashley', lastname: 'Taylor' },
   { id: 13, firstname: 'Joshua', lastname: 'Anderson' },
   { id: 14, firstname: 'Hannah', lastname: 'Thomas' },
   { id: 15, firstname: 'Andrew', lastname: 'Moore' },
   { id: 16, firstname: 'Megan', lastname: 'Jackson' },
   { id: 17, firstname: 'Ryan', lastname: 'Martin' },
   { id: 18, firstname: 'Olivia', lastname: 'Lee' },
   { id: 19, firstname: 'Brandon', lastname: 'White' },
   { id: 20, firstname: 'Alyssa', lastname: 'Harris' },
   { id: 21, firstname: 'Ethan', lastname: 'Clark' },
   { id: 22, firstname: 'Sophia', lastname: 'Lewis' },
   { id: 23, firstname: 'Tyler', lastname: 'Robinson' },
   { id: 24, firstname: 'Mia', lastname: 'Walker' },
   { id: 25, firstname: 'Jack', lastname: 'Young' },
   { id: 26, firstname: 'Lily', lastname: 'King' },
   { id: 27, firstname: 'Logan', lastname: 'Wright' },
   { id: 28, firstname: 'Grace', lastname: 'Scott' },
   { id: 29, firstname: 'Alexander', lastname: 'Green' },
   { id: 30, firstname: 'Isabella', lastname: 'Baker' },
   { id: 31, firstname: 'Mason', lastname: 'Hall' },
   { id: 32, firstname: 'Ella', lastname: 'Adams' },
   { id: 33, firstname: 'Aiden', lastname: 'Nelson' },
   { id: 34, firstname: 'Zoe', lastname: 'Hill' },
   { id: 35, firstname: 'Lucas', lastname: 'Ramirez' },
   { id: 36, firstname: 'Ava', lastname: 'Campbell' },
   { id: 37, firstname: 'Liam', lastname: 'Mitchell' },
   { id: 38, firstname: 'Chloe', lastname: 'Carter' },
   { id: 39, firstname: 'James', lastname: 'Roberts' },
   { id: 40, firstname: 'Natalie', lastname: 'Phillips' }
];
export const assignmentTypeOptions = [
   { value: "false", label: "Assignment" },
   { value: "true", label: "Group Assignment" },
]