import { submitAssignment } from "@/api/common";
import { DangerIcon } from "@/assets/icons";
import { useLayout } from "@/hooks/useLayout";
import { showSuccessToast } from "@/lib/toast";
import { cn, sanitizeHtmlOptions } from "@/lib/utils";
import { IAssignment } from "@/types/assignments.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isAfter } from "date-fns";
import { Formik } from "formik";
import sanitize from "sanitize-html";
import * as Yup from "yup";
import CustomEditor from "./CustomEditor";
import Dropzone from "./Dropzone";
import { Button } from "./ui/button";
import { LessonAttachments } from "./Lessons";
import { MathJax } from "better-react-mathjax";

type Props = {
  assignment: IAssignment;
  closeModal?: () => void;
};

const AssignmentSubmitArea = ({ assignment, closeModal }: Props) => {
  const layout = useLayout();

  if (layout !== "student") return null;

  const submission = assignment.submission;

  return (
    <>
      <p className="ml-auto mt-10 font-semibold text-[#2C323C]">
        Grade: {submission?.grade ? `${submission?.grade}%` : "Not Graded Yet"}
      </p>
      <PreviousSubmission assignment={assignment} />
      <SubmitAssignmentForm assignment={assignment} closeModal={closeModal} />
    </>
  );
};

const PreviousSubmission = ({ assignment }: Props) => {
  if (!assignment.submission) return null;

  const submission = assignment.submission;

  return (
    <div className="mb-10">
      <p className="mb-2 page-header">Submission</p>
      <MathJax>
        <div
          className="mb-5"
          dangerouslySetInnerHTML={{
            __html: sanitize(submission.content, sanitizeHtmlOptions),
          }}
        />
      </MathJax>
      <LessonAttachments
        attachments={submission.attachments}
        className={cn(!!submission.attachments && "hidden")}
      />
      {!!submission.remark && (
        <>
          <p className="mt-8 mb-2 page-header">Remark</p>
          <MathJax>
            <div
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: sanitize(submission.remark, sanitizeHtmlOptions),
              }}
            />
          </MathJax>
        </>
      )}
    </div>
  );
};

const SubmitAssignmentForm = ({ assignment, closeModal }: Props) => {
  const queryClient = useQueryClient();

  const initialValues = {
    id: assignment._id,
    content: "",
    attachments: [],
  };
  const validationSchema = Yup.object().shape({
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
  });

  const { mutate, isLoading } = useMutation(submitAssignment, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      closeModal && closeModal();
      queryClient.refetchQueries({
        queryKey: ["lesson", assignment.lessonNote],
      });
      queryClient.refetchQueries({
        queryKey: ["assignments"],
      });
    },
  });

  if (!!assignment.submission?.grade) return <GradedRender />;

  if (
    isAfter(new Date(), assignment.dueDate) &&
    !assignment.canSubmitAfterDeadline
  )
    return <LateSubmissionRender />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) =>
        mutate(values, { onSuccess: () => resetForm() })
      }
    >
      {({ handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-6 mt-6">
          <p className="page-header">Submit Assignment</p>
          <CustomEditor
            label="Assignment Answer(s)"
            setState={(editorState) => setFieldValue("content", editorState)}
          />
          <Dropzone name="attachments" multiple />

          {isAfter(new Date(), assignment.dueDate) &&
            assignment.canSubmitAfterDeadline && <LateSubmissionRender inner />}

          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              type="submit"
              className="min-w-[100px]"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const LateSubmissionRender = ({ inner }: { inner?: boolean }) => {
  return (
    <div className="flex items-center gap-2 p-3 mt-6 text-red-500 rounded-lg bg-red-50">
      <DangerIcon />
      {inner ? (
        <p className="text-sm font-medium">Due date has passed</p>
      ) : (
        <p className="text-sm font-medium">
          You cannot submit this assignment because it has passed the due date
        </p>
      )}
    </div>
  );
};

const GradedRender = () => {
  return (
    <div className="flex items-center gap-2 p-3 mt-6 text-red-500 rounded-lg bg-red-50">
      <DangerIcon />
      <p className="text-sm font-medium">Assignment has been graded</p>
    </div>
  );
};
export default AssignmentSubmitArea;
