import { addLessonFromBank, getLessonsInBank } from "@/api/common";
import { CustomDataTable, SubjectAndClassSelect } from "@/components";
import CustomSelect from "@/components/CustomSelect";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { useLayout } from "@/hooks/useLayout";
import { cn, showSuccessToast } from "@/lib/utils";
import { IClass } from "@/types/class.types";
import { ILesson } from "@/types/lesson.types";
import { ISubject } from "@/types/subject.types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorMessage, Formik } from "formik";
import { Check, ChevronsUpDown } from "lucide-react";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import * as Yup from "yup";

type Props = {};

const AddLessonFromBank = (props: Props) => {
  const layout = useLayout();
  const { classes, subjects } = useMainLayoutContext();
  const queryClient = useQueryClient();

  const [classId, setClassId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [classOpen, setClassOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<number | null>(null);
  const [subjectOpen, setSubjectOpen] = useState(false);

  const initialValues = {
    week: "",
    subjectId: "",
    classId: "",
    lessons: [],
  };
  const validationSchema = Yup.object().shape({
    week: Yup.string().required("This field is required"),
    subjectId: Yup.string().required("This field is required"),
    classId: Yup.string().required("This field is required"),
    lessons: Yup.array()
      .of(Yup.string().required())
      .min(1, "At least one lesson should be selected")
      .required("Lessons are required"),
  });

  const columns: TableColumn<ILesson>[] = [
    { name: "Lesson Title", selector: (row) => row.topic, grow: 2 },
    {
      name: (
        <div className="flex items-center justify-between gap-2 !px-2">
          Class
          <Popover open={classOpen} onOpenChange={setClassOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                role="combobox"
                aria-expanded={classOpen}
                className="h-auto"
              >
                <ChevronsUpDown className="h-4 w-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput placeholder="Search class..." />
                <CommandList>
                  <CommandEmpty>No class found.</CommandEmpty>
                  <CommandGroup>
                    {classes?.map(({ _id, name }) => (
                      <CommandItem
                        key={_id}
                        value={_id}
                        onSelect={(currentValue) => {
                          setClassId(
                            currentValue === classId ? "" : currentValue,
                          );
                          setClassOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            classId === _id ? "opacity-100" : "opacity-0",
                          )}
                        />
                        {name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      ),
      selector: (row) => (row.class as IClass).name,
    },
    {
      name: (
        <div className="flex items-center justify-between gap-2 !px-2">
          Subject
          <Popover open={subjectOpen} onOpenChange={setSubjectOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                role="combobox"
                aria-expanded={subjectOpen}
                className="h-auto"
              >
                <ChevronsUpDown className="h-4 w-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput placeholder="Search subject..." />
                <CommandList>
                  <CommandEmpty>No subject found.</CommandEmpty>
                  <CommandGroup>
                    {subjects?.map(({ _id, title }) => (
                      <CommandItem
                        key={_id}
                        value={_id}
                        onSelect={(currentValue) => {
                          setSubjectId(
                            currentValue === subjectId ? "" : currentValue,
                          );
                          setSubjectOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            subjectId === _id ? "opacity-100" : "opacity-0",
                          )}
                        />
                        {title}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      ),
      selector: (row) => (row.subject as ISubject).title,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Link
          target="_blank"
          className="text-primary hover:underline"
          to={{ pathname: `/lesson-bank/${row._id}` }}
        >
          View Lesson
        </Link>
      ),
    },
  ];

  const { data, isLoading: isLessonLoading } = useQuery({
    queryKey: ["lesson-bank", page, perPage, classId, subjectId],
    queryFn: () =>
      getLessonsInBank(layout)({
        page,
        perPage,
        limit: 15,
        class: classId,
        subject: subjectId,
      }),
  });
  const { mutate, isLoading } = useMutation(addLessonFromBank, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({ queryKey: ["lesson"] });
    },
  });

  const lessons = data?.data?.docs;
  const totalCount = data?.data?.totalDocs;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-5 pt-8">
          <div className="mb-5 grid gap-5 @[350px]/sheet:grid-cols-2">
            <SubjectAndClassSelect
              subjectValue={values.subjectId}
              subjectOnChange={(val) => setFieldValue("subjectId", val)}
              classOnChange={(val) => setFieldValue("classId", val)}
              classValue={values.classId}
              key={`${values.subjectId}-${values.classId}`}
              customName="subjectId"
              edit
            />
            <CustomSelect
              options={Array(15)
                .fill("")
                .map((_, index) => ({
                  label: (index + 1).toString(),
                  value: (index + 1).toString(),
                }))}
              value={values.week}
              onChange={(val) => setFieldValue("week", val)}
              placeholder="Select week"
              label="Week"
              name="week"
            />
          </div>
          <CustomDataTable
            progressPending={isLessonLoading}
            data={lessons ?? []}
            paginationTotalRows={totalCount}
            onChangePage={(page) => setPage(page)}
            onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
            columns={columns}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) =>
              setFieldValue(
                "lessons",
                selectedRows.map((row) => row._id),
              )
            }
            emptyStateMessage="No lessons"
          />
          <ErrorMessage
            name="lessons"
            component="div"
            className="mt-1 block text-xs text-destructive"
          />
          <div className="ml-auto flex items-center gap-3">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddLessonFromBank;
