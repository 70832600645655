import { gradeAssignmentSubmission } from "@/api/common";
import { Attachment, CustomEditor, CustomInput } from "@/components";
import { Button } from "@/components/ui/button";
import { showSuccessToast } from "@/lib/toast";
import { IAssignmentSubmission } from "@/types/assignments.types";
import { IStudent } from "@/types/user.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MathJax } from "better-react-mathjax";
import { Formik } from "formik";
import * as Yup from "yup";

type Props = {
  closeModal: () => void;
  submission: IAssignmentSubmission;
};

const InfoSection = ({ title, info }: { title: string; info: string }) => {
  return (
    <div>
      <p className="text-xs font-medium uppercase text-[#737D8F]">{title}</p>
      <p className="text-sm font-medium text-[#2C323C]">{info}</p>
    </div>
  );
};

const AssignmentSubmission = ({ closeModal, submission }: Props) => {
  const queryClient = useQueryClient();

  const initialValues = {
    id: submission?._id,
    grade: "",
    remark: "",
  };
  const validationSchema = Yup.object().shape({
    grade: Yup.number()
      .min(0, "Minimum score is zero")
      .max(100, "Maximum score is zero")
      .required("This field is required"),
    remark: Yup.string(),
  });

  const { mutate, isLoading } = useMutation(gradeAssignmentSubmission, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({ queryKey: ["assignment-submissions"] });
      closeModal();
    },
  });

  const attachments = submission?.attachments;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-6">
          <div className="grid gap-5 @[350px]/sheet:grid-cols-2">
            <InfoSection
              title="ADMISSION NUMBER"
              info={(submission?.student as IStudent)?.admissionNumber}
            />
            <InfoSection
              title="STUDENT NAME"
              info={`${(submission?.student as IStudent)?.firstname} ${(submission?.student as IStudent)?.lastname}`}
            />
          </div>
          <div className="grid gap-1">
            <p className="text-xs font-medium uppercase text-[#737D8F]">
              Answer
            </p>
            {!!submission?.content && (
              <MathJax>
                <p
                  className="text-sm font-medium text-[#2C323C]"
                  dangerouslySetInnerHTML={{ __html: submission?.content }}
                />
              </MathJax>
            )}
          </div>
          <div className="flex flex-wrap gap-5">
            {attachments?.map((attachment, index) => (
              <Attachment key={index} attachment={attachment} />
            ))}
          </div>
          <CustomInput
            label="Grade"
            id="grade"
            value={values.grade}
            onChange={handleChange}
            type="number"
            inputMode="numeric"
            placeholder="Enter grade"
            name="grade"
            min={0}
            step={0.01}
            max={100}
          />
          <CustomEditor
            label="Remarks"
            setState={(editorState) => setFieldValue("remark", editorState)}
          />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Grade
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AssignmentSubmission;
