import { getAnnouncementDetails } from "@/api/common";
import { Attachment } from "@/components";
import { useLayout } from "@/hooks/useLayout";
import { IAnnouncement } from "@/types/announcements.types";
import { IClass } from "@/types/class.types";
import { ISubject } from "@/types/subject.types";
import { useQuery } from "@tanstack/react-query";
import { MathJax } from "better-react-mathjax";

type Props = {
  // TODO make mandatory
  announcement: IAnnouncement;
};

const InfoSection = ({ title, info }: { title: string; info: string }) => {
  return (
    <div>
      <p className="text-xs font-medium capitalize text-[#737D8F]">{title}</p>
      <p className="text-sm font-medium text-[#2C323C]">{info}</p>
    </div>
  );
};

const AnnouncementDetails = ({ announcement }: Props) => {
  const layout = useLayout();

  const { data } = useQuery({
    queryKey: ["announcements", announcement?._id],
    queryFn: () => getAnnouncementDetails(announcement?._id),
    suspense: true,
  });

  const announcementDetails = data?.data!;
  const attachments = announcementDetails?.attachments;

  return (
    <div className="grid gap-6">
      <div className="grid gap-5 @[350px]/sheet:grid-cols-2">
        <InfoSection
          title={
            announcementDetails?.lessonNote
              ? "LESSON TITLE"
              : "ANNOUNCEMENT TITLE"
          }
          info={announcementDetails.title}
        />
        <InfoSection
          title="SUBJECT"
          info={(announcementDetails?.subject as ISubject).title}
        />
        {layout !== "student" && (
          <InfoSection
            title="CLASS"
            info={(announcementDetails?.class as IClass).name}
          />
        )}
      </div>
      <div className="grid gap-1">
        <p className="text-xs font-medium capitalize text-[#737D8F]">
          ANNOUNCEMENT:
        </p>
        <MathJax>
          <p
            className="text-sm font-medium text-[#2C323C]"
            dangerouslySetInnerHTML={{ __html: announcementDetails.content }}
          />
        </MathJax>
      </div>
      <div className="flex flex-wrap gap-5">
        {attachments.map((attachment, index) => (
          <Attachment key={index} attachment={attachment} />
        ))}
      </div>
    </div>
  );
};

export default AnnouncementDetails;
