import { Suspense } from "react";
import { Loader } from "./components";
import RouterComponent from "./Router";

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <RouterComponent />
    </Suspense>
  );
};

export default App;
