import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "mathquill-node/lib/mathquill.css";
import "./editor.css";
import { cn } from "@/lib/utils";
import { useEffect, useState, Component, Dispatch } from "react";
import MathQuillEditor from "react-mq-editor";
import { Button } from "../ui/button";
import MathJaxIcon from "src/assets/images/math_jax.png"

type Props = {
  defaultEditorState?: any;
  setState: (editorState: any) => void;
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
};

const CustomEditor = ({
  defaultEditorState,
  setState,
  containerClassName,
  label,
  labelClassName,
}: Props) => {
  const [isMathJaxAdded, setIsMathJaxAdded] = useState(false);
  const [latex, setLatex] = useState("");

  const [editorState, setEditorState] = useState(defaultEditorState)

  useEffect(() => {
    let string = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (editorState.getCurrentContent().hasText() && string.indexOf("\\(") !== -1 && string.indexOf("\\)") !== -1) {
      let start = string.indexOf("\\(");
      let end = string.indexOf("\\)");

      setLatex(string.substring(start + 2, end));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function insertCharacter(characterToInsert, editorState) {
    if (!characterToInsert || !editorState) return editorState;

    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    if (!currentSelection) return editorState;

    const newContent = Modifier.replaceText(currentContent, currentSelection, characterToInsert);
    const newEditorState = EditorState.push(editorState, newContent, "insert-characters");

    setLatex("");

    // Force selection to the new cursor position and return the updated state
    return setEditorState(EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
  }

  useEffect(() => {
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState])


  return (
    <div className={containerClassName}>
      {!!label && (
        <label
          className={cn(
            "mb-1 block text-sm font-medium text-[#495364]",
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <div className={cn("editor-tooltip", !isMathJaxAdded && "!hidden")}>
        <MathQuillEditor
          latex={latex}
          config={{
            handlers: {
              edit: (mathField) => {
                setLatex(mathField.latex());
              },
            },
          }}
        />
        <div className="flex items-end justify-between w-full p-0">
          <a href={`symbols.pdf`} className="mb-1 sample-download" target="_blank" rel="noreferrer">
            View list of all mathematical symbols and equations
          </a>
          <Button
            type="button"
            onClick={() => insertCharacter("\\(".concat(latex, "\\)"), editorState)}
          >
            Insert formula
          </Button>
        </div>
      </div>
      <Editor
        editorState={editorState}
        onEditorStateChange={(editorState) => {
          setEditorState(editorState);
        }}
        {...(!!defaultEditorState && { defaultEditorState })}
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbarCustomButtons={[
          <MathJaxOption setIsMathJaxAdded={setIsMathJaxAdded} />,
        ]}
      />
    </div>
  );
};

interface MathJaxOptionProps {
  setIsMathJaxAdded: Dispatch<React.SetStateAction<boolean>>;
}

class MathJaxOption extends Component<MathJaxOptionProps> {
  onIconClickEvent = () => {
    const { setIsMathJaxAdded } = this.props;

    setIsMathJaxAdded((prevState) => !prevState);
  };

  render() {
    return (
      <button type="button" title="Mathematic equations and symbols" className="mathjax-icon" onClick={this.onIconClickEvent}>
        <div className="rdw-option-wrapper">
          <img src={MathJaxIcon} alt="MathJax" style={{ width: 15, objectFit: "contain" }} />
        </div>
      </button>
    );
  }
}

export default CustomEditor;
