import { lazy } from "react";

export const HomePage = lazy(() => import("./HomePage"));
export const LessonsPage = lazy(() => import("./LessonsPage"));
export const StudentLessonPage = lazy(() => import("./StudentLessonPage"));
export const LoginPage = lazy(() => import("./Login"));
export const LoginWithCodePage = lazy(() => import("./LoginWithCode"));
export const ForgotPasswordPage = lazy(() => import("./ForgotPassword"));
export const StudentsPage = lazy(() => import("./StudentsPage"));
export const InstructorsPage = lazy(() => import("./InstructorsPage"));
export const LessonsDetailsPage = lazy(() => import("./LessonsDetailsPage"));
export const AssignmentsPage = lazy(() => import("./AssignmentsPage"));
export const ProfilePage = lazy(() => import("./ProfilePage"));
export const SettingsPage = lazy(() => import("./SettingsPage"));
export const AssignmentSubmissions = lazy(
  () => import("./AssignmentSubmissions"),
);
export const AnnouncementPage = lazy(() => import("./AnnouncementPage"));
export const LessonsBankPage = lazy(() => import("./LessonsBankPage"));
export const LessonBankDetailsPage = lazy(
  () => import("./LessonBankDetailsPage"),
);
export const SubjectsPage = lazy(() => import("./SubjectsPage"));
export const SubjectLessonsPage = lazy(() => import("./SubjectLessonsPage"));
export const StudentAssignmentPage = lazy(
  () => import("./StudentAssignmentPage"),
);
export const StudentsAnnouncementPage = lazy(
  () => import("./StudentsAnnouncementPage"),
);
export const LiveClassPage = lazy(() => import("./LiveClassPage"));
export const TokenAuth = lazy(() => import("./TokenAuth"));
