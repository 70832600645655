import { cn } from "@/lib/utils";
import { forwardRef, HTMLAttributes } from "react";
import { Skeleton } from "./ui/skeleton";
import { LoaderIcon } from "lucide-react";

interface DashboardItemProps extends HTMLAttributes<HTMLDivElement> {
  valueClassName?: string;
  iconWrapperClassName?: string;
  title: string;
  value?: string | number;
  icon: React.ReactNode;
  loading?: boolean;
  fetching?: boolean;
}

const DashboardItem = forwardRef<HTMLDivElement, DashboardItemProps>(
  (
    {
      icon,
      iconWrapperClassName,
      valueClassName,
      title,
      className,
      value = "N/A",
      loading,
      fetching,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          "relative flex cursor-default items-center gap-2 rounded-xl border border-[#E5EBF0] px-4 py-5 md:gap-4",
          className,
        )}
        ref={ref}
        {...props}
      >
        {fetching && (
          <LoaderIcon className="absolute right-4 top-4 size-4 animate-spin" />
        )}
        <span
          className={cn(
            "inline-flex size-9.5 flex-shrink-0 items-center justify-center rounded-full bg-primary-transparent p-1 text-primary",
            iconWrapperClassName,
          )}
        >
          {icon}
        </span>

        <div className="space-y-2">
          <h3 className="text-[#576378]">{title}</h3>
          {loading ? (
            <Skeleton className="h-5 w-8" />
          ) : (
            <div
              className={cn("text-2xl font-bold text-header", valueClassName)}
            >
              {value}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default DashboardItem;
