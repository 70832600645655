import { EyeIcon, EyeSlash } from "@/assets/icons";
import { cn } from "@/lib/utils";
import { ErrorMessage } from "formik";
import { ReactNode, useState } from "react";
import { Button } from "./ui/button";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode;
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  innerContainerClassName?: string;
  showPasswordToggle?: boolean;
}

const CustomInput = ({
  icon,
  label,
  containerClassName,
  labelClassName,
  innerContainerClassName,
  showPasswordToggle,
  ...props
}: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => setPasswordVisible((prev) => !prev);

  return (
    <div className={containerClassName}>
      {!!label && (
        <label
          htmlFor={props["id"]}
          className={cn(
            "mb-1 block text-sm font-medium text-[#495364]",
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <div
        className={cn(
          "flex items-center gap-2 rounded-md border border-[#EAEBF0] text-sm has-[:focus]:border-primary has-[:disabled]:bg-[#FBFBFB]",
          props.type === "password" ? "relative" : "",
          innerContainerClassName,
        )}
      >
        <span className="pl-3">{icon}</span>
        {props.type === "password" ? (
          <>
            <input
              className={cn(
                "text-bodyText w-full rounded-md !border-0 py-3 text-sm !outline-0 placeholder:text-[#a8b0bd] focus:![--tw-ring-shadow:transparent] disabled:cursor-not-allowed disabled:bg-[#FBFBFB]",
                !!icon ? "px-2" : "px-5",
                props.className,
              )}
              {...props}
              type={passwordVisible ? "text" : "password"}
            />
            {showPasswordToggle && (
              <Button
                size="icon"
                variant="ghost"
                type="button"
                className="position-center-y absolute right-1 max-h-[80%] rounded-full text-[#737D8F]"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (
                  <EyeIcon className="size-5" />
                ) : (
                  <EyeSlash className="size-5" />
                )}
              </Button>
            )}
          </>
        ) : (
          <input
            {...props}
            className={cn(
              "text-bodyText w-full rounded-md !border-0 p-0 py-3 text-sm !outline-0 placeholder:text-[#a8b0bd] focus:![--tw-ring-shadow:transparent] disabled:cursor-not-allowed disabled:bg-[#FBFBFB]",
              !!icon ? "px-2" : "px-5",
              props["className"],
            )}
          />
        )}
      </div>
      {!!props["name"] && (
        <ErrorMessage
          name={props["name"]}
          component="div"
          className="mt-1 block text-xs text-destructive"
        />
      )}
    </div>
  );
};

export default CustomInput;
