import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { useLayout } from "./useLayout";

const roleRoutes = {
   admin: '/admin',
   teacher: '/teacher',
   student: '',
};

const useLayoutNavigate = () => {
   const navigate = useNavigate();
   const layout = useLayout()

   const navigateWithLayout = (path: To, options?: NavigateOptions) => {
      const rolePath = roleRoutes[layout];

      if (typeof path === 'string') {
         navigate(`${rolePath}${path}`, options);
      } else if (typeof path === 'object' && path.pathname) {
         navigate({
            ...path,
            pathname: `${rolePath}${path.pathname}`,
         }, options);
      } else {
         console.error('Invalid path format.');
      }
   };

   return navigateWithLayout;
}

export default useLayoutNavigate