import {
  enrolStudentsForSubjects,
  getAllocatedClassSubjects,
  getStudentClassSubjects,
} from "@/api/admin";
import { CustomCheckBox } from "@/components";
import { Button } from "@/components/ui/button";
import { SheetClose } from "@/components/ui/sheet";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { IClass } from "@/types/class.types";
import { ISession } from "@/types/sessions.types";
import { ISubject } from "@/types/subject.types";
import { IStudent } from "@/types/user.types";
import { showErrorToast, showSuccessToast } from "@/lib/toast";
import { useMutation, useQueries } from "@tanstack/react-query";
import { ErrorMessage, Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";

type Props = {
  currentStudent?: IStudent;
  onClose: () => void;
};
type FormType = {
  class: string;
  students: string[];
  subjects: string[];
  session: string;
  term: string;
};

const ManageStudentEnrollments = ({ currentStudent, onClose }: Props) => {
  const { currentTermId, terms } = useMainLayoutContext();
  const currentTerm = terms?.find((term) => term._id === currentTermId);

  const classId = (currentStudent?.class as IClass)._id;
  const studentId = currentStudent?.student?._id;

  const [{ data: rawAllocations }, { data: rawStudentSubjects }] = useQueries({
    queries: [
      {
        queryKey: ["class-allocated-subjects", classId],
        queryFn: () => getAllocatedClassSubjects({ classId }),
        suspense: true,
      },
      {
        queryKey: ["student-class-subjects", studentId, currentTermId],
        queryFn: () =>
          getStudentClassSubjects({
            studentId,
            termId: currentTermId,
            limit: 50,
          }),
        suspense: true,
      },
    ],
  });

  const allocations = rawAllocations?.data!;
  const studentSubjects = rawStudentSubjects?.data?.docs?.at(0)
    ?.subject as ISubject[];

  useEffect(() => {
    if (!allocations.length) {
      showErrorToast("No subjects for this student's class");
      onClose();
    }
  }, [allocations, onClose]);

  const initialValues: FormType = {
    class: (currentStudent?.class as IClass)._id,
    students: [(currentStudent?.student as IStudent)._id],
    subjects: studentSubjects?.map((subject) => subject._id) ?? [],
    session: (currentTerm?.session as ISession)._id,
    term: currentTermId!,
  };
  const validationSchema = Yup.object().shape({
    class: Yup.string().required("Class is required"),
    students: Yup.array()
      .of(Yup.string().required())
      .min(1, "At least one student should be selected"),
    subjects: Yup.array()
      .of(Yup.string().required())
      .min(1, "At least one subject should be selected"),
    session: Yup.string().required("Session is required"),
    term: Yup.string().required("Term is required"),
  });

  const { mutate, isLoading } = useMutation(enrolStudentsForSubjects, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      onClose();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="grid gap-8">
          <div className="grid grid-cols-2 gap-4">
            {allocations?.map((allocation, i) => {
              const subject = allocation.subject as ISubject;

              return (
                <CustomCheckBox
                  labelClassName="capitalize"
                  id={subject._id}
                  type="checkbox"
                  key={i}
                  checked={values.subjects?.includes(subject._id)}
                  className="size-5 border-border"
                  label={subject.title}
                  value={subject?._id}
                  onChange={() => {
                    values.subjects.includes(subject._id)
                      ? setFieldValue(
                          "subjects",
                          values.subjects.filter((sub) => sub !== subject._id),
                        )
                      : setFieldValue("subjects", [
                          ...values.subjects,
                          subject._id,
                        ]);
                  }}
                />
              );
            })}
          </div>
          <ErrorMessage
            name="class"
            component="div"
            className="mt-1 block text-xs text-destructive"
          />
          <ErrorMessage
            name="students"
            component="div"
            className="mt-1 block text-xs text-destructive"
          />
          <ErrorMessage
            name="subjects"
            component="div"
            className="mt-1 block text-xs text-destructive"
          />
          <ErrorMessage
            name="session"
            component="div"
            className="mt-1 block text-xs text-destructive"
          />
          <ErrorMessage
            name="term"
            component="div"
            className="mt-1 block text-xs text-destructive"
          />
          <div className="ml-auto flex items-center gap-3">
            <SheetClose asChild>
              <Button type="button" variant="outline">
                Cancel
              </Button>
            </SheetClose>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ManageStudentEnrollments;
