import { LoginCredentials } from "@/pages/Login";
import { type Layout } from "@/hooks/useLayout";
import { type PasswordData } from "@/panels/UpdatePassword";
import axios from "axios";
import {
   onReqFulfilled,
   onReqRejected,
   onResFulfilled,
   onResRejected,
} from "./helpers";
import { ApiResponse, PaginatedResponse } from "@/types/api.types";
import { ILesson, ILessonByWeek } from "@/types/lesson.types";
import { IClassLevel } from "@/types/classLevels.types";
import { ISubject } from "@/types/subject.types";
import { ITerms } from "@/types/terms.types";
import { IClass } from "@/types/class.types";
import { ILoginResponse, User } from "@/types/user.types";
import {
   IAssignment,
   IAssignmentSubmission,
   IGroupAssignmentSubmission,
} from "@/types/assignments.types";
import { IAnnouncement } from "@/types/announcements.types";
import { ITenant } from "@/types/tenants.types";
import { INotification } from "@/types/notifications.types";

const api = axios.create({
   baseURL: process.env.REACT_APP_API_BASEURL,
   // headers: { "Content-Type": "application/json" }
});

api.interceptors.request.use(onReqFulfilled, onReqRejected);
api.interceptors.response.use(onResFulfilled, onResRejected);

export default api;

// Auth
export const loginFn =
   (layout: Layout = "student") =>
      (data: LoginCredentials): Promise<ILoginResponse> =>
         api.post(`/auth/${layout}/login`, data);

export const changePasswordFn =
   (layout: Layout = "student") =>
      (data: Partial<PasswordData>): Promise<ILoginResponse> =>
         api.post(`/auth/${layout}/change-password`, data);

export const generateUserToken = (data): Promise<ApiResponse<string>> => api.post("/auth/generate-user-token", data)

export const verifyUserToken = (data): Promise<ILoginResponse> => api.post("/auth/verify-user-token", data)

export const verifySchoolCubeToken = (data): Promise<ILoginResponse> => api.post("/auth/verify-external-token", data)

export const getSchools = (): Promise<ApiResponse<ITenant[]>> => api.get("/tenants");

export const getSchool = (code): Promise<ApiResponse<ITenant>> => api.get(`/tenants/${code}`);

//? Class Levels
export const getClassLevels = (): Promise<ApiResponse<IClassLevel[]>> =>
   api.get("/class-levels");

export const createClassLevel = ({ id, ...data }): Promise<ApiResponse<IClassLevel>> => api.post("/class-levels", data);

export const updateClassLevel = ({ id, ...data }): Promise<ApiResponse<IClassLevel>> => api.patch(`/class-levels/${id}`, data);

//? Subjects
export const getSubjects = (params): Promise<ApiResponse<ISubject[]>> =>
   api.get("/subjects", { params });

export const createSubject = ({ id, ...data }): Promise<ApiResponse<ISubject>> => api.post("/subjects", data);

export const updateSubject = ({ id, ...data }): Promise<ApiResponse<ISubject>> => api.post(`/subjects/${id}`, data);

export const getPaginatedSubjects = (params): Promise<ApiResponse<PaginatedResponse<ISubject[]>>> =>
   api.get("/subjects", { params });

// ?Classes
export const getClasses = (params): Promise<ApiResponse<IClass[]>> =>
   api.get("/classes", { params });

export const getPaginatedClasses = (params): Promise<ApiResponse<PaginatedResponse<IClass[]>>> => api.get("/classes", { params });

export const createClass = ({ id, ...data }): Promise<ApiResponse<IClass>> =>
   api.post("/classes", data);

export const updateClass = ({ id, ...data }): Promise<ApiResponse<IClass>> =>
   api.patch(`/classes/${id}`, data);

export const getStudentCount = (params): Promise<ApiResponse<{ total: number }>> =>
   api.get("/classes/total-student", { params });

export const getAllocationStudents =
   (layout: Layout) =>
      (params): Promise<ApiResponse<PaginatedResponse<{ student: User }[]>>> =>
         api.get(`/${layout}/students`, { params });

// ? Terms
export const getTerms = (): Promise<ApiResponse<ITerms[]>> => api.get("/terms");

// ? Lessons

export const getLessonsInBank =
   (layout: Layout) =>
      (params): Promise<ApiResponse<PaginatedResponse<ILesson[]>>> =>
         api.get(`/${layout}/lessons/bank`, { params });

export const createLesson = ({ id, ...data }): Promise<ApiResponse<ILesson>> =>
   api.post("/lessons", data, {
      headers: { "Content-Type": "multipart/form-data" },
   });

export const createLessonInBank = (data): Promise<ApiResponse<ILesson>> =>
   api.post("/lesson-bank", data, {
      headers: { "Content-Type": "multipart/form-data" },
   });

export const getLessons =
   (layout: Layout) =>
      (params): Promise<ApiResponse<PaginatedResponse<ILesson[]>>> =>
         api.get(`/${layout}/lessons`, { params });

export const updateLesson = ({ id, ...data }): Promise<ApiResponse<ILesson>> =>
   api.post(`/lessons/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
   });

export const updateLessonInBank = ({
   id,
   ...data
}): Promise<ApiResponse<ILesson>> =>
   api.post(`/lesson-bank/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
   });

export const getLesson = (id: string): Promise<ApiResponse<{ lesson: ILesson; lessonsByWeeks: ILessonByWeek[] }>> =>
   api.get(`/lessons/${id}`);

export const getLessonInBank = (id: string): Promise<ApiResponse<ILesson>> =>
   api.get(`/lesson-bank/${id}`);

export const addLessonFromBank = (data): Promise<ApiResponse> =>
   api.post(`/lessons/copy`, data);

export const approveLesson = ({ id }): Promise<ApiResponse> => api.post(`/lessons/${id}/approve`);

export const rejectLesson = ({ id }): Promise<ApiResponse> => api.post(`/lessons/${id}/reject`);

// ? Assignment
export const createAssignment = (data): Promise<ApiResponse<IAssignment>> =>
   api.post("/assignments", data, { headers: { "Content-Type": "multipart/form-data" } });

export const updateAssignment = ({ id, ...data }): Promise<ApiResponse<IAssignment>> => api.post(`/assignments/${id}`, data, { headers: { "Content-Type": "multipart/form-data" } })

export const getAssignments = (layout: Layout) => (params): Promise<ApiResponse<PaginatedResponse<IAssignment[]>>> => api.get(layout === "admin" ? `/assignments` : `/${layout}/assignments`, { params });

export const getAssignmentDetails = (id): Promise<ApiResponse<IAssignment>> =>
   api.get(`/assignments/${id}`);

export const getAssignmentSubmissions = (id, params): Promise<ApiResponse<PaginatedResponse<IAssignmentSubmission[]>>> =>
   api.get(`/assignments/${id}/submissions`, { params });

export const getGroupAssignmentSubmissions = (id, params): Promise<ApiResponse<IGroupAssignmentSubmission[]>> =>
   api.get(`/assignments/${id}/submissions/group`, { params });

export const deleteAssignment = ({ id }): Promise<ApiResponse> =>
   api.delete(`/assignments/${id}`);

export const gradeAssignmentSubmission = ({ id, ...data }): Promise<ApiResponse<IAssignmentSubmission>> =>
   api.post(`/assignments/submissions/${id}/grade`, data);

export const gradeGroupAssignmentSubmission = (data): Promise<ApiResponse> =>
   api.post(`/assignments/submissions/grade`, data);

export const submitAssignment = ({ id, ...data }): Promise<ApiResponse> =>
   api.post(`/assignments/${id}/submit`, data, { headers: { "Content-Type": "multipart/form-data" } });

// ? Announcements
export const createAnnouncement = (data): Promise<ApiResponse<IAnnouncement>> =>
   api.post("/announcements", data, { headers: { "Content-Type": "multipart/form-data" } });

export const updateAnnouncement = ({ id, ...data }): Promise<ApiResponse<IAnnouncement>> => api.post(`/announcements/${id}`, data, { headers: { "Content-Type": "multipart/form-data" } })

export const getAnnouncements = (layout: Layout) => (params): Promise<ApiResponse<PaginatedResponse<IAnnouncement[]>>> => api.get(layout === "admin" ? `/announcements` : `/${layout}/announcements`, { params });

export const getAnnouncementDetails = (id): Promise<ApiResponse<IAnnouncement>> => api.get(`/announcements/${id}`);

export const deleteAnnouncement = ({ id }): Promise<ApiResponse> => api.delete(`/announcements/${id}`);

export const getNotifications = (params): Promise<ApiResponse<PaginatedResponse<INotification[]>>> => api.get("/notifications", { params })

export const markNotificationAsRead = (id): Promise<ApiResponse> => api.patch(`/notifications/${id}`)