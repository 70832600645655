import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { AssignmentSubmission, GroupAssignmentSubmission } from "@/panels";
import {
  IAssignment,
  IAssignmentSubmission,
  IGroupAssignmentSubmission,
} from "@/types/assignments.types";
import { IClass } from "@/types/class.types";
import { IStudent } from "@/types/user.types";
import { TableColumn } from "react-data-table-component";
import CustomDataTable from "./CustomDataTable";
import CustomSheet from "./CustomSheet";
import { Button } from "./ui/button";

type GroupSubmissionProps = {
  groupSubmission: IGroupAssignmentSubmission;
};

const GroupSubmission = ({ groupSubmission }: GroupSubmissionProps) => {
  const columns: TableColumn<IAssignmentSubmission>[] = [
    {
      name: "S/N",
      selector: (_, index) => (index as number) + 1,
      width: "90px",
    },
    {
      name: "Student",
      selector: (row) =>
        `${(row.student as IStudent)?.firstname} ${(row.student as IStudent)?.lastname}`,
      grow: 2.5,
    },
    {
      name: "Admission No",
      selector: (row) => (row.student as IStudent)?.admissionNumber,
    },
    {
      name: "Class",
      selector: (row) =>
        ((row.assignment as IAssignment)?.class as IClass)?.name,
    },
    {
      name: "Grade",
      selector: (row) => (!!row.grade ? `${row.grade}%` : "-"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <CustomSheet
          trigger={
            <Button
              variant="custom"
              className="px-0 text-primary hover:underline"
            >
              Grade
            </Button>
          }
          title="Submission Details"
        >
          {(closeModal) => (
            <AssignmentSubmission closeModal={closeModal} submission={row} />
          )}
        </CustomSheet>
      ),
      width: "180px",
    },
  ];

  const submissions = groupSubmission.submissions ?? [];

  return (
    <AccordionItem
      className="rounded-lg bg-white"
      value={`item-${groupSubmission._id}`}
    >
      <AccordionTrigger className="gap-6 !rounded-lg bg-white">
        <div className="flex flex-1 flex-wrap items-center justify-between">
          <p>{groupSubmission.name}</p>
          <div className="flex items-center gap-3">
            <p className="text-sm font-medium text-[#495364]">
              Date submitted:{" "}
              <span className={cn("text-red-500")}>02/10/2024</span>
            </p>
            <CustomSheet
              trigger={<Button className="h-auto !py-2">Grade</Button>}
              title="Group Submission"
            >
              {(closeModal) => (
                <GroupAssignmentSubmission
                  submissions={submissions}
                  closeModal={closeModal}
                />
              )}
            </CustomSheet>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <CustomDataTable
          data={submissions}
          columns={columns}
          paginationServer={false}
          emptyStateMessage="No submissions"
        />
      </AccordionContent>
    </AccordionItem>
  );
};

export default GroupSubmission;
