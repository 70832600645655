import { getNotifications, markNotificationAsRead } from "@/api/common";
import {
  Notification as NotificationIcon,
  RightArrowIcon,
} from "@/assets/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useLayout } from "@/hooks/useLayout";
import useLayoutNavigate from "@/hooks/useLayoutNavigate";
import { showSuccessToast } from "@/lib/toast";
import { INotification } from "@/types/notifications.types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import EmptyState from "./EmptyState";
import { Button } from "./ui/button";

const NotificationsDropdown = () => {
  const layout = useLayout();

  const { data } = useQuery({
    queryKey: ["notifications"],
    queryFn: () => getNotifications({ unread: true, limit: 7, page: 1 }),
    suspense: true,
    enabled: layout !== "admin",
    refetchInterval: 15000
  })

  const notifications = data?.data?.docs

  if (layout === "admin") return null

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className="relative rounded-full"
        >
          <NotificationIcon className="size-6" />
          <span className="sr-only">Toggle notifications</span>
          {!!notifications?.length && (
            <span className="absolute right-2.5 top-3.5 inline-block size-2 rounded-full border border-background bg-destructive">
              <span className="sr-only">{2} new notifications</span>
            </span>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-w-[480px] min-w-[300px]">
        {notifications?.length ?
          <>
            <DropdownMenuLabel className="px-3 py-2 text-lg font-semibold">
              Notifications
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {notifications?.map(notification => <Notification notification={notification} key={notification._id} />)}
          </> :
          <EmptyState className="min-h-[350px]">
            <p>No new notifications</p>
          </EmptyState>
        }
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

type Props = { notification: INotification }

const Notification = ({ notification }: Props) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(markNotificationAsRead, {
    onSuccess: ({ message }) => {
      showSuccessToast(message, { closeButton: true })
      queryClient.refetchQueries({ queryKey: ["notifications"] })
    }
  })
  return (
    <DropdownMenuItem className="grid px-3 py-2">
      <div className="grid grid-cols-[1fr,auto] max-w-[350px] items-start justify-between gap-3">
        <p className="text-sm font-medium text-[#495364]">
          {notification.message}
        </p>
        <span className="text-xs text-[#8F97A5] max-w-[100px]">{formatDistanceToNow(notification.createdAt, { addSuffix: true })}</span>
      </div>
      <div className="flex items-center justify-between">
        <RedirectButton notification={notification} />
        <Button
          variant="custom"
          className="items-center h-auto gap-1 px-2 py-2 text-xs font-semibold leading-none w-fit text-primary hover:bg-gray-200"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            mutate(notification._id)
          }}
        >
          Mark as seen
        </Button>
      </div>
    </DropdownMenuItem>
  );
};

const RedirectButton = ({ notification }: Props) => {
  const layout = useLayout()
  const navigate = useLayoutNavigate();

  if (notification.type === "announcement") return (
    <Button
      variant="custom"
      className="items-center gap-1 px-0 text-sm font-semibold w-fit text-primary"
      onClick={() => navigate(`/announcements?announcementId=${notification.announcement}`)}
    >
      View Announcement <RightArrowIcon />
    </Button>
  )

  if (notification.type === "assignment") return (
    <Button
      variant="custom"
      className="items-center gap-1 px-0 text-sm font-semibold w-fit text-primary"
      onClick={() => navigate(`/assignments?assignmentId=${notification.assignment}`)}
    >
      View Assignment <RightArrowIcon />
    </Button>
  )

  if (notification.type === "lesson") return (
    <Button
      variant="custom"
      className="items-center gap-1 px-0 text-sm font-semibold w-fit text-primary"
      onClick={() => navigate(`/lessons/${notification.lessonNote}`)}
    >
      View Lesson <RightArrowIcon />
    </Button>
  )

  if (notification.type === "submission" && layout === "teacher") return (
    <Button
      variant="custom"
      className="items-center gap-1 px-0 text-sm font-semibold w-fit text-primary"
      onClick={() => navigate(`/assignments/${notification.assignment}`)}
    >
      View Submission <RightArrowIcon />
    </Button>
  )

  if (notification.type === "submission" && layout === "student") return (
    <Button
      variant="custom"
      className="items-center gap-1 px-0 text-sm font-semibold w-fit text-primary"
      onClick={() => navigate(`/assignments?assignmentId=${notification.assignment}`)}
    >
      View Submission <RightArrowIcon />
    </Button>
  )

  return (
    <Button
      variant="custom"
      className="items-center gap-1 px-0 text-sm font-semibold w-fit text-primary"
    >
      View Lesson <RightArrowIcon />
    </Button>
  )
}

export default NotificationsDropdown;
