import { createAnnouncement, updateAnnouncement } from "@/api/common";
import {
  CustomEditor,
  CustomInput,
  Dropzone,
  SubjectAndClassSelect,
} from "@/components";
import { Button } from "@/components/ui/button";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { useLayout } from "@/hooks/useLayout";
import {
  cn,
  convertToEditorState,
  removeEmptyOrNull,
  showSuccessToast,
} from "@/lib/utils";
import { IAnnouncement } from "@/types/announcements.types";
import { IClass } from "@/types/class.types";
import { ILesson } from "@/types/lesson.types";
import { ISubject } from "@/types/subject.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import * as Yup from "yup";

type Props = {
  lesson?: ILesson;
  closeModal: () => void;
};
type EditProps = {
  announcement: IAnnouncement;
  closeModal: () => void;
};

const AddAnnouncement = ({ lesson, closeModal }: Props) => {
  const layout = useLayout();
  const queryClient = useQueryClient();
  const { currentTermId } = useMainLayoutContext();

  const initialValues = {
    title: lesson?.topic ?? "",
    content: "",
    lessonNote: lesson?._id ?? "",
    subject: (lesson?.subject as ISubject)?._id ?? "",
    class: (lesson?.class as IClass)?._id ?? "",
    term: currentTermId,
    attachments: [],
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
    term: Yup.string(),
  });

  const { mutate, isLoading } = useMutation(createAnnouncement, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({ queryKey: ["lesson"] });
      queryClient.refetchQueries({ queryKey: ["announcements"] });
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(removeEmptyOrNull(values))}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-4">
          {!lesson && (
            <>
              <CustomInput
                label="Announcement Title"
                value={(values as any).title}
                onChange={handleChange}
                placeholder="Enter announcement title"
                name="title"
              />
              <div
                className={cn(
                  "grid gap-4",
                  layout === "admin" && "@[350px]/sheet:grid-cols-2",
                )}
              >
                <SubjectAndClassSelect
                  subjectValue={values.subject}
                  subjectOnChange={(val) => setFieldValue("subject", val)}
                  classOnChange={(val) => setFieldValue("class", val)}
                  key={`${values.subject}-${values.class}`}
                  classValue={values.class}
                  edit
                />
              </div>
            </>
          )}
          <CustomEditor
            label="Announcement"
            setState={(editorState) => setFieldValue("content", editorState)}
          />
          <Dropzone name="attachments" multiple />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export const EditAnnouncement = ({ announcement, closeModal }: EditProps) => {
  const layout = useLayout();
  const queryClient = useQueryClient();

  const initialValues = {
    id: announcement?._id ?? "",
    title: announcement?.title ?? "",
    content: announcement.content ?? "",
    subject: (announcement?.subject as ISubject)?._id ?? "",
    class: (announcement?.class as IClass)?._id ?? "",
    term: announcement.term as string,
    attachments: [],
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
  });

  const { mutate, isLoading } = useMutation(updateAnnouncement, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({ queryKey: ["lesson"] });
      queryClient.refetchQueries({ queryKey: ["announcements"] });
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(removeEmptyOrNull(values) as any)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-4">
          <CustomInput
            label="Announcement Title"
            value={(values as any).title}
            onChange={handleChange}
            placeholder="Enter announcement title"
            name="title"
          />
          <div
            className={cn(
              "grid gap-4",
              layout === "admin" && "@[350px]/sheet:grid-cols-2",
            )}
          >
            <SubjectAndClassSelect
              subjectValue={values.subject}
              subjectOnChange={(val) => setFieldValue("subject", val)}
              classOnChange={(val) => setFieldValue("class", val)}
              key={`${values.subject}-${values.class}`}
              classValue={values.class}
              edit
            />
          </div>
          <CustomEditor
            label="Announcement"
            setState={(editorState) => setFieldValue("content", editorState)}
            defaultEditorState={convertToEditorState(values.content)}
          />
          <Dropzone name="attachments" multiple />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddAnnouncement;
