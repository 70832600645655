import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { ILessonStatus } from "@/types/lesson.types";
import sanitizeHtml, { type IOptions } from "sanitize-html";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const removeItemAtIndex = (
  array: any[],
  indexToRemove: number,
  allowEmpty: boolean = true,
) => {
  const copy = [...array];

  if (indexToRemove >= 0 && indexToRemove < copy.length) {
    copy.splice(indexToRemove, 1);
  }

  if (!allowEmpty && copy.length === 0) {
    return array;
  }

  return copy;
};

export const studentsPerGroupRange = (
  totalStudents: number,
  numberOfGroups: number,
): string => {
  const baseGroupSize = Math.floor(totalStudents / numberOfGroups);
  const extraStudents = totalStudents % numberOfGroups;

  if (extraStudents === 0) {
    return ` ${baseGroupSize} `;
  }

  const minGroupSize = baseGroupSize;
  const maxGroupSize = baseGroupSize + 1;

  return ` between ${minGroupSize} and ${maxGroupSize} `;
};

export const noop = () => { };

export const getStatusClassName = (
  status: ILessonStatus,
  className?: string,
  noBackground?: boolean,
) => {
  return cn(
    "font-semibold text-sm py-0.5 px-2 capitalize rounded-[5px]",
    {
      "text-[#2d8a39]": status === "approved",
      "text-[#eea23e]": status === "draft",
      "text-destructive": status === "rejected",
    },
    !noBackground
      ? {
        "bg-[#F0FAF0]": status === "approved",
        "bg-[#FFF8EB]": status === "draft",
        "bg-destructive/5": status === "rejected",
      }
      : "",
    className,
  );
};

export function getCurrentLayout() {
  const pathname = window.location.pathname;

  return pathname.startsWith("/teacher")
    ? "teacher"
    : pathname.startsWith("/admin")
      ? "admin"
      : "student";
}

export function getLayoutLocation(to?: string) {
  const layout = getCurrentLayout();
  const linkAncestor = layout === "student" ? "" : `/${layout}`;
  return `${linkAncestor}${to}`;
}

export function removeEmptyOrNull<T extends Record<string, any>>(
  obj: T,
): Partial<T> | undefined {
  if (!obj) return undefined;

  const filteredEntries = Object.entries(obj).filter(
    ([_, value]) => value !== "" && value !== null,
  );

  return filteredEntries.length > 0
    ? (Object.fromEntries(filteredEntries) as Partial<T>)
    : {};
}

export const convertToEditorState = (markup) => {
  if (!markup) return EditorState.createEmpty();

  const blocksFromHTML = convertFromHTML(markup);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return EditorState.createWithContent(state);
};

export const sanitizeHtmlOptions: IOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
    "ul",
    "ol",
    "img",
    "ins",
  ]),
  allowedAttributes: {
    a: ["name", "href"],
    img: ["src"],
  },
  disallowedTagsMode: "recursiveEscape",
};

export const renderNumber = (amount: number | string) => new Intl.NumberFormat("en-GB", {
  currencyDisplay: "narrowSymbol",
}).format(Number(amount));

export const truncateString = (input: string): string => {
  const maxLength = 20;
  const ellipsis = "...";

  if (input.length <= maxLength) {
    return input;
  }

  return input.slice(0, maxLength) + ellipsis;
}

export * from "./storage";
export * from "./toast";
