import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { ILessonByWeek } from "@/types/lesson.types";
import LessonButton from "./LessonButton";

type LessonAccordionProps = {
  tab?: boolean;
  lessonsByWeeks: ILessonByWeek[];
};

const LessonAccordion = ({ tab, lessonsByWeeks }: LessonAccordionProps) => {
  return (
    <Accordion
      type="multiple"
      className={cn(
        "flex h-fit max-h-[650px] w-full flex-col gap-3 overflow-y-scroll",
        !tab && "max-md:hidden",
      )}
    >
      {lessonsByWeeks?.map((week) => (
        <LessonAccordionItem key={week._id} week={week} />
      ))}
    </Accordion>
  );
};

type LessonAccordionItemProps = {
  week: ILessonByWeek;
};

const LessonAccordionItem = ({ week }: LessonAccordionItemProps) => {
  const { _id, lessons } = week;
  return (
    <AccordionItem value={`item-${_id}`} className="h-fit">
      <AccordionTrigger>Week {_id}</AccordionTrigger>
      <AccordionContent>
        {lessons?.map((lesson) => (
          <LessonButton key={lesson._id} lesson={lesson} />
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};

export default LessonAccordion;
