import { Formik } from "formik";
import { Button, buttonVariants } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { CustomInput, CustomSelect } from "@/components";
import { IClass } from "@/types/class.types";
import * as Yup from "yup";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createClass, updateClass } from "@/api/common";
import { showSuccessToast } from "@/lib/toast";
import { IClassLevel } from "@/types/classLevels.types";

type EditArmProps = {
  closeModal: () => void;
  arm?: IClass;
};

const EditArm = ({ closeModal, arm }: EditArmProps) => {
  const queryClient = useQueryClient();
  const { classes } = useMainLayoutContext();

  const initialValues = {
    id: arm?._id ?? "",
    name: arm?.name ?? "",
    level: (arm?.level as IClassLevel)?._id ?? "",
  };
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required("This field is required"),
    level: Yup.string().required("This field is required"),
  });

  const { mutate, isLoading } = useMutation(!!arm ? updateClass : createClass, {
    onSuccess: ({ message }) => {
      showSuccessToast(message, { closeButton: true });
      queryClient.invalidateQueries({
        queryKey: ["classes-paginated"],
        type: "active",
      });
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form
          className="flex flex-col justify-between flex-grow h-full gap-6 pb-8"
          onSubmit={handleSubmit}
        >
          <div className="py-4 space-y-5">
            <CustomInput
              label="Class name"
              id="name"
              name="name"
              placeholder="Enter Class name"
              value={values.name}
              onChange={handleChange}
            />
            <CustomSelect
              options={
                classes?.map(({ name, _id }) => ({
                  value: _id,
                  label: name,
                })) ?? []
              }
              label="Level"
              id="level"
              name="level"
              placeholder="Select level"
              value={values.level}
              onChange={(val) => setFieldValue("level", val)}
            />
          </div>
          <div className="flex items-center justify-end w-full gap-2">
            <DialogClose
              type="button"
              className={buttonVariants({
                variant: "outline",
              })}
            >
              Cancel
            </DialogClose>
            <Button disabled={isLoading} className="min-w-[100px]">
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default EditArm;
