import React, { ReactNode } from "react";
import DataTable, {
  PaginationComponentProps,
  TableProps,
} from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import { EmptyStateIcon, LeftIcon } from "@/assets/icons";
import { cn } from "@/lib/utils";
import Loader from "./Loader";
import CustomSelect from "./CustomSelect";
import EmptyState from "./EmptyState";

interface CustomDataTableProps<T> extends TableProps<T> {
  children?: ReactNode;
  containerClassName?: string;
  emptyStateMessage?: React.ReactNode;
  alterParams?: boolean;
}

const CustomDataTable = <T,>({
  children,
  containerClassName,
  emptyStateMessage,
  alterParams = true,
  ...props
}: CustomDataTableProps<T>) => {
  const [searchParams] = useSearchParams();

  const perPage = parseInt(searchParams.get("per_page") || "15");

  return (
    <div
      className={cn(
        "table-responsive flex w-full flex-col overflow-x-scroll",
        containerClassName,
      )}
    >
      <DataTable
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationPerPage={perPage}
        paginationComponent={Pagination}
        noDataComponent={
          <EmptyState icon={<EmptyStateIcon />}>
            <div className="max-w-[240px] font-semibold text-[#2C323C]">
              {emptyStateMessage}
            </div>
          </EmptyState>
        }
        customStyles={{
          table: {
            style: {
              ...(!!props?.data?.length && { border: "1px solid #EAEBF0" }),
              borderRadius: 10,
              marginBottom: "4rem",
              backgroundColor: "transparent",
              overflow: "auto",
              "&>div": { backgroundColor: "transparent" },
            },
          },
          rows: {
            style: {
              fontSize: "0.875rem",
              fontWeight: 500,
              color: "#495364",
              backgroundColor: "#FFFFFFF6",
              borderBottom: "1px solid #EAEBF0",
              paddingBlock: "1.15rem",
            },
          },
          headRow: {
            style: {
              fontSize: "0.875rem",
              fontWeight: 600,
              color: "#041020",
              backgroundColor: "#FFFFFFF6",
            },
          },
        }}
        {...props}
      />
      {children}
    </div>
  );
};

const options = [
  { value: "1", label: "1" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
];

const Pagination = ({
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
}: PaginationComponentProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (key, value) => {
    // Clone the current search params to preserve other params
    const newSearchParams = new URLSearchParams(searchParams);

    // Update the specific param
    newSearchParams.set(key, value);

    // Set the new search params
    setSearchParams(newSearchParams);
  };

  const rowsPerPage = parseInt(searchParams.get("per_page") || "0") || 15;

  const pageCount = Math.ceil(rowCount / rowsPerPage);

  const handlePageClick = ({ selected }) => {
    updateSearchParams("page", selected + 1);
    onChangePage(selected + 1, rowCount);
  };
  const handlePerPageClick = (perPage: number) => {
    updateSearchParams("per_page", perPage);
    onChangeRowsPerPage(perPage, rowCount);
  };

  if (pageCount === 1) return null;

  return (
    <div className="flex flex-wrap-reverse gap-5">
      <div className="flex items-center gap-1 font-display text-sm text-[#576378]">
        <p>Show</p>
        <CustomSelect
          options={options}
          className="gap-2 px-[0.625rem]"
          value={searchParams.get("per_page") || "15"}
          onChange={(val) => handlePerPageClick(Number(val))}
          placeholder="Number"
        />
        <p>entries per page</p>
      </div>
      <ReactPaginate
        breakLabel="..."
        previousLabel={
          <LeftIcon className="h-[30px] w-[30px] text-[#576378]" />
        }
        nextLabel={<LeftIcon className="h-[30px] w-[30px] rotate-180 text-[#576378]" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        forcePage={
          searchParams.has("page")
            ? parseInt(searchParams.get("page") || "1") - 1
            : 0
        }
        containerClassName="custom-pagination"
        pageClassName="custom-pagination-page"
        previousClassName="custom-pagination-previous"
        nextClassName="custom-pagination-next"
      />
    </div>
  );
};

export default CustomDataTable;
