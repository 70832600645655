import { type ReactNode } from "react";
import { toast, type ExternalToast } from "sonner";
import {
  AlertCircleFillIcon,
  CheckCircleFillIcon,
  InfoCircleFillIcon,
  XCircleFillIcon,
} from "@/assets/icons";

export const showSuccessToast = (
  message: string | ReactNode,
  data?: ExternalToast,
) =>
  toast.success(message, {
    icon: <CheckCircleFillIcon className="text-green-500 size-5" />,
    ...data,
  });

export const showErrorToast = (
  message: string | ReactNode,
  data?: ExternalToast,
) =>
  toast.error(message, {
    icon: <XCircleFillIcon className="text-red-500 size-5" />,
    ...data,
  });

export const showInfoToast = (
  message: string | ReactNode,
  data?: ExternalToast,
) =>
  toast.info(message, {
    icon: <InfoCircleFillIcon className="text-blue-500 size-5" />,
    ...data,
  });

export const showLoadingToast = (
  message: string | ReactNode,
  data?: ExternalToast,
) => toast.loading(message, data);

export const showWarningToast = (
  message: string | ReactNode,
  data?: ExternalToast,
) =>
  toast.warning(message, {
    icon: <AlertCircleFillIcon className="text-yellow-500 size-5" />,
    ...data,
  });

export const dismissToast = (id?: number | string) => toast.dismiss(id);
