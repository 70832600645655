import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { X } from "lucide-react";

interface SharedProps {
  title: React.ReactNode;
  children: React.ReactNode | ((onClose: () => void) => React.ReactNode);
  footer?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
}
interface CustomModalPropsUnControlled extends SharedProps {
  trigger: React.ReactNode;
  open?: never;
  onOpenChange?: never;
}
interface CustomModalPropsControlled extends SharedProps {
  trigger?: never;
  open: boolean;
  onOpenChange: (state: boolean) => void;
}

type Props = CustomModalPropsUnControlled | CustomModalPropsControlled;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className,
    )}
    {...props}
  />
);

export const CustomModal = ({
  title,
  description,
  children,
  footer,
  className,
  ...rest
}: Props) => {
  const closeModal = () => {
    const closeButton = document.querySelector("#modal-close-button");
    if (closeButton) (closeButton as HTMLElement).click();
  };

  if (rest["open"]) {
    const { open, onOpenChange } = rest;

    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent
          className={cn(
            "z-50 flex max-h-[80vh] flex-col overflow-y-auto p-0 sm:max-w-md",
            className,
          )}
        >
          <DialogHeader className="sticky top-0 z-10 h-fit bg-background">
            <div className="flex flex-row items-start justify-between border-b border-b-input px-4 py-4 md:px-6 lg:px-8">
              <div className="w-full">
                <DialogTitle className="max-w-[80%] truncate text-nowrap text-left text-lg font-semibold text-header">
                  {title}
                </DialogTitle>
                <DialogDescription className="text-left">
                  {description}
                </DialogDescription>
              </div>

              <DialogClose
                type="button"
                id="modal-close-button"
                className="!m-0 aspect-square w-fit rounded-full p-1.5 hover:bg-accent"
              >
                <X className="size-4" />
              </DialogClose>
            </div>
          </DialogHeader>
          <div className="flex-1 px-4 md:px-6 lg:px-8">
            {typeof children === "function" ? children(closeModal) : children}
          </div>
          {footer && (
            <DialogFooter className="sm:justify-start">{footer}</DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  const { trigger } = rest;
  return (
    <Dialog>
      {trigger}

      <DialogContent
        className={cn(
          "z-50 flex max-h-[80vh] flex-col overflow-y-auto p-0 sm:max-w-md",
          className,
        )}
      >
        <DialogHeader className="sticky top-0 z-10 h-fit bg-background">
          <div className="flex flex-row items-start justify-between border-b border-b-input px-4 py-4 md:px-6 lg:px-8">
            <div className="w-full">
              <DialogTitle className="max-w-[80%] truncate text-nowrap text-left text-lg font-semibold text-header">
                {title}
              </DialogTitle>
              <DialogDescription className="text-left">
                {description}
              </DialogDescription>
            </div>

            <DialogClose
              type="button"
              id="modal-close-button"
              className="!m-0 aspect-square w-fit rounded-full p-1.5 hover:bg-accent"
            >
              <X className="size-4" />
            </DialogClose>
          </div>
        </DialogHeader>
        <div className="flex-1 px-4 md:px-6 lg:px-8">
          {typeof children === "function" ? children(closeModal) : children}
        </div>
        {footer && (
          <DialogFooter className="sm:justify-start">{footer}</DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default CustomModal;
