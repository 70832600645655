import { EditIcon, EllipsisIcon, EyeIcon } from "@/assets/icons";
import { cn, sanitizeHtmlOptions, truncateString } from "@/lib/utils";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import RouteLink from "./RouteLink";
import CustomPopover from "./CustomPopover";
import CustomSheet from "./CustomSheet";
import { AddSingleLesson } from "@/panels";
import { useLayout } from "@/hooks/useLayout";
import useLayoutNavigate from "@/hooks/useLayoutNavigate";
import { ILesson } from "@/types/lesson.types";
import { ISubject } from "@/types/subject.types";
import chroma from "chroma-js";
import sanitize from "sanitize-html";
import { Skeleton } from "./ui/skeleton";
import { MathJax } from "better-react-mathjax";

interface LessonCardProps {
  lesson: ILesson;
  className?: string;
  titleStyle?: React.CSSProperties;
  titleClassName?: string;
}

const LessonCard = ({ lesson, className, titleClassName }: LessonCardProps) => {
  const navigate = useLayoutNavigate();
  const layout = useLayout();

  const background = chroma.random();
  const textColor = background.luminance() > 0.5 ? "black" : "white";

  return (
    <Card
      className={cn(
        "group/lessonCard relative flex flex-col overflow-hidden rounded-[10px] border-none font-display",
        className,
      )}
    >
      {layout !== "student" && (
        <CustomPopover
          align="end"
          trigger={
            <Button
              className="absolute z-10 rounded-full right-4 top-4 size-8 bg-background text-foreground"
              size={"icon"}
            >
              <EllipsisIcon />
            </Button>
          }
          actions={[
            {
              label: (
                <div className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-primary-transparent hover:text-primary">
                  <EyeIcon className="h-[18px] w-[18px]" />
                  View Lesson
                </div>
              ),
              onClick: () => navigate({ pathname: `/lessons/${lesson._id}` }),
            },
            {
              label: (
                <CustomSheet
                  trigger={
                    <div className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-primary-transparent hover:text-primary">
                      <EditIcon className="h-[18px] w-[18px]" />
                      Edit Lesson
                    </div>
                  }
                  title="Edit Lesson"
                >
                  {(closeModal) => (
                    <AddSingleLesson closeModal={closeModal} lesson={lesson} />
                  )}
                </CustomSheet>
              ),
            },
          ]}
        />
      )}
      <RouteLink to={`/lessons/${lesson._id || ""}`}>
        <CardContent
          className={cn(
            !(lesson.subject as Partial<ISubject>).thumbnail
              ? "subject-card-img"
              : "",
            "grid h-[14.44rem] place-items-center pt-7 text-2xl font-bold uppercase",
            titleClassName,
          )}
          style={
            (lesson.subject as Partial<ISubject>).thumbnail
              ? {
                backgroundImage: `url(${(lesson.subject as Partial<ISubject>).thumbnail})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }
              : {
                backgroundColor: background.css("hsl"),
                color: textColor,
              }
          }
        >
          <span
            className={cn(
              "text-center transition-all duration-500 group-hover/lessonCard:[text-shadow:0_1px_4px_rgba(0,0,0,0.5)]",
              (lesson.subject as Partial<ISubject>).thumbnail
                ? "rounded-2xl bg-black/50 px-4 py-2 text-white backdrop-blur"
                : "",
            )}
          >
            {(lesson.subject as Partial<ISubject>).title}
          </span>
        </CardContent>
      </RouteLink>
      <RouteLink className="flex-grow" to={`/lessons/${lesson._id || ""}`}>
        <CardHeader className="flex h-full flex-row items-center justify-between rounded-b-[10px] border border-t-0 border-border/50 bg-background p-4 text-sm max-[300px]:items-start">
          <div className="flex-1 gap-4 space-y-1.5">
            <CardTitle className="text-base font-medium text-header">
              {truncateString(lesson.topic)} {lesson.week && `- Week ${lesson.week}`}
            </CardTitle>
            {lesson.week && (
              <MathJax>
                <CardDescription
                  className="line-clamp-1 [&_*]:!bg-transparent [&_*]:!font-light [&_*]:!text-inherit"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(lesson.content || "", sanitizeHtmlOptions),
                  }}
                />
              </MathJax>
            )}
          </div>

          <span className="font-medium text-[#4CAF5C]">View Lesson</span>
        </CardHeader>
      </RouteLink>
    </Card>
  );
};

export const LessonCardSkeletons = ({ amount = 4 }: { amount?: number }) => (
  <div className="grid items-stretch gap-6 sm:grid-cols-[repeat(auto-fit,minmax(358px,1fr))]">
    {Array.from({ length: amount }).map((_, i) => (
      <Skeleton className="w-full h-64 round-xl" key={i} />
    ))}
  </div>
);

export default LessonCard;
