import {
  DownloadIcon,
  PDFIcon,
  PresentationIcon,
  SheetIcon,
  WordDocumentIcon,
} from "@/assets/icons";
import { IAttachment } from "@/types/others.types";
import { Link } from "react-router-dom";

type Props = {
  attachment: IAttachment;
};

function getFileExtension(filename) {
  var ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
}
function formatFileSize(bytes: number): string {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (bytes >= megabyte) {
    return (bytes / megabyte).toFixed(2) + " MB";
  } else if (bytes >= kilobyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else {
    return bytes + " bytes";
  }
}

const returnAttachmentIcon = (url) => {
  const fileType = getFileExtension(url);
  if (fileType === "pdf") return <PDFIcon />;
  else if (fileType === "doc" || fileType === "docx" || fileType === "txt")
    return <WordDocumentIcon />;
  else if (fileType === "xlsx" || fileType === "xlsm") return <SheetIcon />;
  else if (fileType === "ppt" || fileType === "pptx")
    return <PresentationIcon />;
};

const Attachment = ({ attachment }: Props) => {
  return (
    <Link
      to={attachment?.url!}
      download={true}
      target="_blank"
      className="flex items-center gap-2 rounded-lg border border-[#E5E9F2] px-2 py-2"
    >
      {returnAttachmentIcon(attachment?.name)}
      <div>
        <p className="max-w-[350px] truncate font-sans text-sm font-semibold text-[#3A4250]">
          {attachment?.name}
        </p>
        <p className="font-sans text-xs text-[#576378]">
          {formatFileSize(attachment?.size!)}
        </p>
      </div>
      <DownloadIcon />
    </Link>
  );
};

export default Attachment;
