import { cn } from "@/lib/utils";
import ClassCubeLogo from "./ClassCubeLogo";

type Props = {
  className?: string;
};

const Loader = ({ className }: Props) => {
  return (
    <div
      className={cn(
        "grid min-h-[50vh] w-full items-center justify-center",
        className,
      )}
    >
      <ClassCubeLogo className="animate-pulse" />
    </div>
  );
};

export default Loader;
