import { cn } from "@/lib/utils";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import RouteLink from "./RouteLink";
import { ISubject } from "@/types/subject.types";
import chroma from "chroma-js";
import { Skeleton } from "./ui/skeleton";
import { ILesson } from "@/types/lesson.types";

interface SubjectCardProps {
  subject: ISubject & { topLesson: ILesson };
  className?: string;
  titleStyle?: React.CSSProperties;
  titleClassName?: string;
}

const SubjectCard = ({
  subject,
  className,
  titleClassName,
}: SubjectCardProps) => {
  const background = chroma.random();
  const textColor = background.luminance() > 0.5 ? "black" : "white";

  return (
    <Card
      className={cn(
        "group/lessonCard relative flex flex-col overflow-hidden rounded-[10px] border-none font-display",
        className,
      )}
    >
      <RouteLink to={`/lessons/${subject.topLesson._id || ""}`}>
        <CardContent
          className={cn(
            !subject.thumbnail ? "subject-card-img" : "",
            "grid h-[14.44rem] place-items-center pt-7 text-2xl font-bold uppercase",
            titleClassName,
          )}
          style={
            subject.thumbnail
              ? {
                  backgroundImage: `url(${subject.thumbnail})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }
              : {
                  backgroundColor: background.css("hsl"),
                  color: textColor,
                }
          }
        >
          <span
            className={cn(
              "text-center transition-all duration-500 group-hover/lessonCard:[text-shadow:0_1px_4px_rgba(0,0,0,0.5)]",
              subject.thumbnail
                ? "rounded-2xl bg-black/50 px-4 py-2 text-white backdrop-blur"
                : "",
            )}
          >
            {subject.title}
          </span>
        </CardContent>
      </RouteLink>
      <RouteLink
        className="flex-grow"
        to={`/lessons/${subject.topLesson._id || ""}`}
      >
        <CardHeader className="flex h-full flex-row items-center justify-between rounded-b-[10px] border border-t-0 border-border/50 bg-background p-4 text-sm max-[300px]:items-start">
          <div className="flex-1 gap-4 space-y-1.5">
            <CardTitle className="text-base font-medium text-header">
              {subject.title}{" "}
              {subject.topLesson.week && `- Week ${subject.topLesson.week}`}
            </CardTitle>
          </div>

          <span className="font-medium text-[#4CAF5C]">View Lesson</span>
        </CardHeader>
      </RouteLink>
    </Card>
  );
};

export const SubjectCardSkeletons = ({ amount = 4 }: { amount?: number }) => (
  <div className="grid items-stretch gap-6 sm:grid-cols-[repeat(auto-fit,minmax(358px,1fr))]">
    {Array.from({ length: amount }).map((_, i) => (
      <Skeleton className="round-xl h-64 w-full" key={i} />
    ))}
  </div>
);

export default SubjectCard;
