import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { useLayout } from "@/hooks/useLayout";
import CustomSelect from "./CustomSelect";

interface Props {
  selectClassName?: string;
  edit?: boolean;
  subjectValue: string;
  classValue: string;
  customName?: string;
  subjectOnChange: (value: string) => void;
  classOnChange: (value: string) => void;
}

const SubjectAndClassSelect = (props: Props) => {
  const {
    selectClassName,
    edit,
    subjectValue,
    classValue,
    subjectOnChange,
    classOnChange,
    customName,
  } = props;
  const layout = useLayout();
  const { subjects, allocations, classes } = useMainLayoutContext();

  if (layout === "admin") {
    return (
      <>
        <CustomSelect
          options={
            subjects?.map(({ _id, title }) => ({
              label: title,
              value: _id,
            })) ?? []
          }
          value={subjectValue}
          key={subjectValue}
          onChange={(val) => subjectOnChange(val)}
          placeholder="Subject"
          containerClassName={selectClassName}
          {...(!!edit && { name: "subject", label: "Subject" })}
        />
        <CustomSelect
          options={
            classes?.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })) ?? []
          }
          value={classValue}
          key={classValue}
          onChange={(val) => classOnChange(val)}
          placeholder="Class"
          containerClassName={selectClassName}
          {...(!!edit && { name: "class", label: "Class" })}
        />
      </>
    );
  }

  return (
    <>
      <CustomSelect
        options={
          allocations?.map(({ class: allocationClass, subject }) => {
            const classValue = (
              typeof allocationClass === "string"
                ? allocationClass
                : allocationClass._id
            ) as string;
            const className = (
              typeof allocationClass === "string"
                ? allocationClass
                : allocationClass.name
            ) as string;
            const subjectValue = (
              typeof subject === "string" ? subject : subject._id
            ) as string;
            const subjectName = (
              typeof subject === "string" ? subject : subject.title
            ) as string;

            return {
              value: subjectValue.concat("-", classValue),
              label: subjectName.concat(" ", className),
            };
          }) || []
        }
        value={
          !!subjectValue && !!classValue
            ? subjectValue.concat("-", classValue)
            : ""
        }
        key={
          !!subjectValue && !!classValue
            ? subjectValue.concat("-", classValue)
            : ""
        }
        onChange={(val) => {
          const [subjectVal, classVal] = val.split("-");
          subjectOnChange(subjectVal);
          classOnChange(classVal);
        }}
        placeholder="Allocation"
        className={selectClassName}
        {...(!!edit && { name: customName ?? "subject", label: "Allocation" })}
      />
    </>
  );
};

export default SubjectAndClassSelect;
