import { getAssignmentDetails } from "@/api/common";
import { Attachment } from "@/components";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { IAssignment, IAssignmentGroup } from "@/types/assignments.types";
import { IClass } from "@/types/class.types";
import { ISubject } from "@/types/subject.types";
import { IStudent } from "@/types/user.types";
import { useQuery } from "@tanstack/react-query";
import { MathJax } from "better-react-mathjax";
import { formatDate } from "date-fns";

type Props = {
  assignment: IAssignment;
};

const InfoSection = ({ title, info }: { title: string; info: string }) => {
  return (
    <div>
      <p className="text-xs font-medium capitalize text-[#737D8F]">{title}</p>
      <p className="text-sm font-medium text-[#2C323C]">{info}</p>
    </div>
  );
};

const AssignmentDetails = ({ assignment }: Props) => {
  const { data } = useQuery({
    queryKey: ["assignment", assignment._id],
    queryFn: () => getAssignmentDetails(assignment._id),
    suspense: true,
  });

  const assignmentDetails = data?.data!;
  const attachments = assignmentDetails?.attachments;
  const groups = assignmentDetails?.groups;

  return (
    <div className="grid gap-6">
      <div className="grid gap-5 @[350px]/sheet:grid-cols-2">
        <InfoSection
          title={
            assignmentDetails?.lessonNote ? "LESSON TITlE" : "ASSIGNMENT TITLE"
          }
          info={assignmentDetails?.title}
        />
        <InfoSection
          title="DUE DATE"
          info={formatDate(new Date(assignmentDetails?.dueDate), "PPpp")}
        />
        <InfoSection
          title="SUBJECT"
          info={(assignmentDetails?.subject as ISubject).title}
        />
        <InfoSection
          title="CLASS"
          info={(assignmentDetails?.class as IClass).name}
        />
      </div>
      <div className="grid gap-1">
        <p className="text-xs font-medium capitalize text-[#737D8F]">
          ASSIGNMENT QUESTION
        </p>
        <MathJax>
          <p
            className="text-sm font-medium text-[#2C323C]"
            dangerouslySetInnerHTML={{ __html: assignmentDetails?.content }}
          />
        </MathJax>
      </div>
      <div className="flex flex-wrap gap-5">
        {attachments.map((attachment, index) => (
          <Attachment key={index} attachment={attachment} />
        ))}
      </div>
      {!!groups?.length && (
        <div className="grid gap-2">
          <p className="text-xs font-medium capitalize text-[#737D8F]">
            Groups
          </p>
          <Accordion
            type="multiple"
            className={cn("grid h-fit max-h-[650px] w-full gap-3")}
          >
            {groups?.map((group, index) => (
              <AssignmentGroup key={index} group={group} />
            ))}
          </Accordion>
        </div>
      )}
    </div>
  );
};

type AssignmentGroupProps = {
  group: IAssignmentGroup;
};

const AssignmentGroup = ({ group }: AssignmentGroupProps) => {
  const students = group.students;

  return (
    <AccordionItem value={`item-${group._id}`}>
      <AccordionTrigger>{group.name}</AccordionTrigger>
      <AccordionContent>
        {students?.map((student, index) => (
          <StudentInfo key={index} student={student} />
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};

const StudentInfo = ({ student }: { student: Partial<IStudent> }) => {
  return (
    <div>
      <p className="font-medium text-[#3A4250]">
        {student.firstname} {student.lastname}
      </p>
      <p className="text-sm text-[#495364]">{student.admissionNumber}</p>
    </div>
  );
};

export default AssignmentDetails;
