import useLayoutNavigate from "@/hooks/useLayoutNavigate";
import { getFromSessionStorage, setToSessionStorage } from "@/lib/storage";
import { showSuccessToast } from "@/lib/toast";
import { ITenant } from "@/types/tenants.types";
import { User } from "@/types/user.types";
import React, {
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

type AuthContextType = {
  isAuthenticated: boolean;
  user?: User | null;
  token?: string | null;
  school?: ITenant | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  setToken: Dispatch<SetStateAction<string | null>>;
  setSchool: Dispatch<SetStateAction<ITenant | null>>;
  logout: () => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const layoutNavigate = useLayoutNavigate();

  const [user, setUser] = useState(() => getFromSessionStorage("user"));
  const [token, setToken] = useState(() => getFromSessionStorage("token"));
  const [school, setSchool] = useState(() => getFromSessionStorage("school"));

  useEffect(() => {
    setToSessionStorage("user", user);
    setToSessionStorage("token", token);
    setToSessionStorage("school", school);
  }, [user, token, school]);

  function logout() {
    setUser(null);
    setToken(null);
    layoutNavigate("/login");
    showSuccessToast("Logout successful!");
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user && !!token,
        token,
        setToken,
        setUser,
        user,
        logout,
        school,
        setSchool,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
