import { IAttachment } from "@/types/others.types";
import Attachment from "../Attachment";
import EmptyState from "../EmptyState";

type Props = {
  attachments: IAttachment[];
  className?: string;
};

const LessonAttachments = ({ attachments, className }: Props) => {
  if (!attachments?.length)
    return (
      <EmptyState className={className}>
        <div className="max-w-[240px] font-semibold text-[#2C323C]">
          No attachments
        </div>
      </EmptyState>
    );

  return (
    <div className="flex flex-wrap gap-5">
      {attachments.map((attachment, index) => (
        <Attachment attachment={attachment} key={index} />
      ))}
    </div>
  );
};

export default LessonAttachments;
