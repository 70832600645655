import { createLessonInBank, updateLessonInBank } from "@/api/common";
import {
  CustomEditor,
  CustomInput,
  Dropzone,
  SubjectAndClassSelect,
} from "@/components";
import { Button } from "@/components/ui/button";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { useLayout } from "@/hooks/useLayout";
import { cn, convertToEditorState, showSuccessToast } from "@/lib/utils";
import { IClass } from "@/types/class.types";
import { ILesson } from "@/types/lesson.types";
import { ISubject } from "@/types/subject.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import * as Yup from "yup";

type Props = {
  lesson?: ILesson;
  closeModal: () => void;
};

const AddSingleLessonToBank = ({ lesson, closeModal }: Props) => {
  const layout = useLayout();
  const queryClient = useQueryClient();
  const { currentTermId } = useMainLayoutContext();

  const initialValues = {
    id: lesson?._id ?? "",
    topic: lesson?.topic ?? "",
    subject: (lesson?.subject as ISubject)?._id ?? "",
    class: (lesson?.class as IClass)?._id ?? "",
    content: lesson?.content ?? "",
    term: currentTermId,
    attachments: [],
  };
  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
  });

  const { mutate, isLoading } = useMutation(
    !!lesson ? updateLessonInBank : createLessonInBank,
    {
      onSuccess: ({ message }) => {
        showSuccessToast(message);
        queryClient.refetchQueries({
          queryKey: ["lesson-bank"],
        });
        closeModal();
      },
    },
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-5 pt-8">
          <CustomInput
            label="Lesson Topic"
            value={values.topic}
            onChange={handleChange}
            placeholder="Enter lesson title"
            name="topic"
          />
          <div
            className={cn(
              "grid gap-4",
              layout === "admin" && "@[350px]/sheet:grid-cols-2",
            )}
          >
            <SubjectAndClassSelect
              subjectValue={values.subject}
              subjectOnChange={(val) => setFieldValue("subject", val)}
              classOnChange={(val) => setFieldValue("class", val)}
              key={`${values.subject}-${values.class}`}
              classValue={values.class}
              edit
            />
          </div>
          <CustomEditor
            label="Lesson Notes"
            setState={(editorState) => setFieldValue("content", editorState)}
            defaultEditorState={convertToEditorState(values.content)}
          />
          <Dropzone name="attachments" multiple />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddSingleLessonToBank;
