import Avatar from "@/assets/images/avatar.png";
import { IAnnouncement } from "@/types/announcements.types";
import { formatRelative } from "date-fns";
import Attachment from "../Attachment";
import EmptyState from "../EmptyState";
import sanitize from "sanitize-html";
import { sanitizeHtmlOptions } from "@/lib/utils";
import { MathJax } from "better-react-mathjax";

type Props = {
  announcements: IAnnouncement[];
};

const LessonAnnouncements = ({ announcements }: Props) => {
  if (!announcements?.length)
    return (
      <EmptyState>
        <div className="max-w-[240px] font-semibold text-[#2C323C]">
          No announcements
        </div>
      </EmptyState>
    );

  return (
    <div className="grid gap-12">
      {announcements.map((announcement, index) => (
        <Announcement key={index} announcement={announcement} />
      ))}
    </div>
  );
};

const Announcement = ({ announcement }: { announcement: IAnnouncement }) => {
  const attachments = announcement.attachments;

  return (
    <div className="grid gap-2">
      <div className="flex items-center gap-2 mb-2">
        <img src={Avatar} className="h-[45px] w-[45px] rounded-full" alt="" />
        <div>
          <p className="font-medium font-display text-primary">
            {announcement.announcementBy.firstname}{" "}
            {announcement.announcementBy.lastname}
          </p>
          <p className="font-sans text-sm text-[#737D8F]">
            posted{" "}
            {formatRelative(new Date(announcement.createdAt), new Date())}.
          </p>
        </div>
      </div>
      <h3 className="font-bold">{announcement.title}</h3>
      <MathJax>
        <div
          className="max-w-[850px] text-[#495364]"
          dangerouslySetInnerHTML={{
            __html: sanitize(announcement.content, sanitizeHtmlOptions),
          }}
        />
      </MathJax>
      <div className="flex flex-wrap gap-5">
        {attachments?.map((attachment, index) => (
          <Attachment attachment={attachment} key={index} />
        ))}
      </div>
    </div>
  );
};

export default LessonAnnouncements;
