import { useLayout } from "@/hooks/useLayout";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  to: string;
}

const RouteLink = ({ children, to, ...rest }: Props) => {
  const layout = useLayout();

  const linkAncestor = layout === "student" ? "" : `/${layout}`;

  const link = `${linkAncestor}${to}`;

  return (
    <Link to={link} {...rest}>
      {children}
    </Link>
  );
};

export default RouteLink;
