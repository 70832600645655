import { AlertDialogCancel } from "@radix-ui/react-alert-dialog";
import { ReactElement, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";

type Props = {
  trigger: ReactElement;
  primaryMessage?: string;
  secondaryMessage?: string;
  cancelText?: string;
  actionText?: string;
  action: () => void;
};

const CustomAlert = ({
  trigger,
  primaryMessage = "Are you absolutely sure?",
  secondaryMessage = "This action cannot be undone. Are you absolutely sure you want to proceed?",
  cancelText = "Cancel",
  actionText = "I am sure",
  action,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{primaryMessage}</AlertDialogTitle>
          <AlertDialogDescription>{secondaryMessage}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="gap-4">
          <AlertDialogCancel>{cancelText}</AlertDialogCancel>
          <AlertDialogAction
            className="disabled:opacity-50"
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              action();
            }}
          >
            {isLoading ? "Loading..." : actionText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CustomAlert;
