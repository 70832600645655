import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Loader2Icon } from "lucide-react";
import * as Yup from "yup";
import { Dropzone } from "@/components";
import { Button, buttonVariants } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { showSuccessToast } from "@/lib/toast";
import { addBulkTeachers } from "@/api/admin";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type AddMultipleInstructorsProps = { onClose?: () => void };

const AddMultipleInstructors = ({ onClose }: AddMultipleInstructorsProps) => {
  const queryClient = useQueryClient();
  const initialValues = { teachers: null };

  const validationSchema = Yup.object().shape({
    teachers: Yup.mixed()
      .required("File is required")
      .test("teachers", "Only Excel files are allowed", (value) => {
        if (value) {
          const file = value as File;
          const supportedFormats = ["xls", "xlsx"];

          return supportedFormats.includes(
            file?.name?.split(".").pop()!.toLowerCase(),
          );
        }
        return true;
      }),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: addBulkTeachers,
    mutationKey: ["teachers", "bulk"],
    onSuccess: (data) => {
      showSuccessToast(data.message || "Teachers added", {
        closeButton: true,
      });

      if (
        data.data?.teachersNotAdded &&
        data.data?.teachersNotAdded.length > 0
      ) {
        showSuccessToast(
          `Teachers not added: ${data.data?.teachersNotAdded.length}`,
          {
            description: (
              <div>
                <h3 className="mb-2 text-sm font-semibold">
                  The following teachers could not be added:
                </h3>
                <ul className="space-y-0.5">
                  {data.data.teachersNotAdded.map((t, i) => (
                    <li className="list-disc text-sm" key={i}>
                      {t?.email}
                    </li>
                  ))}
                </ul>
              </div>
            ),
            closeButton: true,
          },
        );
      }
      queryClient.invalidateQueries({ queryKey: ["teachers"] });

      onClose?.();
    },
  });

  function handleUpload(values) {
    mutate(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpload}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <form
          className="flex flex-col justify-between gap-6 pb-8"
          onSubmit={handleSubmit}
        >
          <Link
            to="/sample-instructors.xlsx"
            target="_blank"
            download
            className="mt-2 w-fit text-sm font-semibold text-primary"
          >
            Download Sample Excel File
          </Link>

          <Dropzone name="teachers" />

          <div className="flex w-full items-center justify-end gap-2">
            <DialogClose
              type="button"
              className={buttonVariants({
                variant: "outline",
                className: "h-8.5",
              })}
            >
              Cancel
            </DialogClose>
            <Button type="submit" className="h-8.5 min-w-[100px]">
              {isLoading ? <Loader2Icon className="animate-spin" /> : "Save"}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddMultipleInstructors;
