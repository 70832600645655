import { useTenantColor } from "@/hooks/useTenantColor";
import { useTheme } from "next-themes";
import { createPortal } from "react-dom";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ style, ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();
  const { primary, primaryTransparent, primaryForeground } = useTenantColor();

  const toasterContent = (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      style={
        {
          "--color-primary": primary,
          "--color-primary-transparent": primaryTransparent,
          "--color-primary-foreground": primaryForeground,
          ...style,
        } as React.CSSProperties
      }
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground ",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  );

  if (typeof window === "undefined") return null;

  return createPortal(toasterContent, document.body);
};

export { Toaster };
