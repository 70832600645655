import { ITeacher } from "@/types/user.types";
import { Button } from "./ui/button";
import { showInfoToast, showSuccessToast } from "@/lib/toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";

interface ShareCredentialsProps {
  data: ITeacher | null;
}

const ShareCredentials = ({ data }: ShareCredentialsProps) => {
  const shareData = {
    title: "Login Credentials",
    text: `Welcome to ["school name"], ${data?.title} ${data?.firstname} ${data?.lastname}. Your login details are as follows: 
    email: ${data?.email}
    Password: ${data?.password}`,
  };

  if (!data) return null;

  async function handleShareCredentials() {
    try {
      await navigator.share(shareData);
      showSuccessToast("Credentials shared successfully!", {
        closeButton: true,
      });
    } catch (err) {
      showInfoToast(
        err instanceof Error ? err?.message : "Error sharing credentials",
        { closeButton: true },
      );
    }
  }

  async function handleCopyCredentials() {
    try {
      await navigator.clipboard.writeText(shareData.text);
      showSuccessToast("Credentials copied to clipboard!", {
        closeButton: true,
        position: "top-left",
      });
    } catch (err) {
      showInfoToast("Error copying credentials", {
        description: err instanceof Error ? err?.message : undefined,
        closeButton: true,
      });
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size={"xs"}>Share</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="mt-4 flex flex-col space-y-1">
        <DropdownMenuItem asChild>
          <Button
            variant="ghost"
            className="w-full justify-between rounded-none"
            onClick={handleShareCredentials}
            type="button"
          >
            Share credentials
          </Button>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Button
            variant="ghost"
            className="w-full justify-between rounded-none"
            onClick={handleCopyCredentials}
            type="button"
          >
            Copy
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ShareCredentials;
