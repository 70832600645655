import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import Video from "@/assets/images/video.png";
import { cn, sanitizeHtmlOptions, showSuccessToast } from "@/lib/utils";
import { buttonVariants } from "../ui/button";
import RouteLink from "../RouteLink";
import { useLayout } from "@/hooks/useLayout";
import { ILesson } from "@/types/lesson.types";
import CustomPopover from "../CustomPopover";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { approveLesson, rejectLesson } from "@/api/common";
import { ApproveIcon, RejectIcon } from "@/assets/icons";
import sanitize from "sanitize-html";
import { MathJax } from "better-react-mathjax";

type LessonContentProps = {
  bank?: boolean;
  lesson: ILesson;
};

const LessonContent = ({ bank, lesson }: LessonContentProps) => {
  const video = lesson?.videoUrl;
  const lessonNote = lesson?.content!;

  const [activeTab, setActiveTab] = useState(!!video ? "video" : "note");

  return (
    <Tabs
      value={activeTab}
      onValueChange={(tab) => setActiveTab(tab)}
      className={cn(activeTab === "note" && "resize-y")}
    >
      <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
        <TabsList variant="lesson-area">
          {!!video && (
            <TabsTrigger variant="lesson-area" value="video">
              Video
            </TabsTrigger>
          )}
          <TabsTrigger variant="lesson-area" value="note">
            Lesson Note
          </TabsTrigger>
        </TabsList>
        <Actions bank={bank} lesson={lesson} />
      </div>
      {!!video && (
        <TabsContent value="video">
          <VideoArea />
        </TabsContent>
      )}
      <TabsContent value="note">
        <LessonNoteArea lessonNote={lessonNote} />
      </TabsContent>
    </Tabs>
  );
};

const Actions = ({ bank, lesson }: LessonContentProps) => {
  const layout = useLayout();
  const queryClient = useQueryClient();
  const status = lesson?.status;
  const assignment = lesson?.assignments;

  const { mutate: approveLessonMutate } = useMutation(approveLesson, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({
        queryKey: ["lesson"],
        type: "active",
      });
    },
  });
  const { mutate: rejectLessonMutate } = useMutation(rejectLesson, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({
        queryKey: ["lesson"],
        type: "active",
      });
    },
  });

  if (layout === "student") return null;

  if (!!bank) return null;

  return (
    <div className="flex items-center gap-3">
      {!!assignment && (
        <RouteLink
          className={buttonVariants({ variant: "accent" })}
          to={`/lessons/${lesson?._id}/assignment/${assignment._id}`}
        >
          View Submitted Assignments
        </RouteLink>
      )}

      <CustomPopover
        trigger={
          <span
            className={buttonVariants({
              variant:
                status === "approved"
                  ? "default"
                  : status === "rejected"
                    ? "destructive"
                    : "draft",
              className: "cursor-pointer capitalize disabled:!opacity-100",
            })}
          >
            {status}
          </span>
        }
        align="end"
        actions={[
          ...(layout === "admin"
            ? [
              {
                label: (
                  <div className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-primary-transparent hover:text-primary">
                    <ApproveIcon className="h-[18px] w-[18px]" />
                    Approve
                  </div>
                ),
                onClick: () => approveLessonMutate({ id: lesson?._id }),
              },
              {
                label: (
                  <div className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-destructive/10 hover:text-destructive">
                    <RejectIcon className="h-[18px] w-[18px]" />
                    Reject
                  </div>
                ),
                onClick: () => rejectLessonMutate({ id: lesson?._id }),
              },
            ]
            : []),
        ]}
      />
    </div>
  );
};

const VideoArea = () => {
  return <img src={Video} alt="" className="h-full mx-auto object-fit" />;
};

const LessonNoteArea = ({ lessonNote }: { lessonNote: string }) => {
  return (
    <MathJax>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(lessonNote, sanitizeHtmlOptions),
        }}
      />
    </MathJax>
  );
};

export default LessonContent;
