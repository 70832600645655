import { verifySchoolCubeToken, verifyUserToken } from "@/api/common";
import { ClassCubeLogo, Loader } from "@/components";
import { setToLocalStorage } from "@/lib/storage";
import { showSuccessToast } from "@/lib/toast";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {}

const TokenAuth = (props: Props) => {
   const [searchParams] = useSearchParams()

   const loginToken = searchParams.get("token");
   const userType = searchParams.get("userType");
   const code = searchParams.get("code");

   const { mutate: verifySchoolCubeTokenMutate } = useMutation(verifySchoolCubeToken, {
      onSuccess: ({ message, data }) => {
         const { user, token, school } = data!;

         sessionStorage.setItem("token", JSON.stringify(token));
         sessionStorage.setItem("user", JSON.stringify(user));
         sessionStorage.setItem("school", JSON.stringify(school));
         setToLocalStorage("code", school.code);

         showSuccessToast(message || "Login successful!", {
            description: `Welcome back, ${user.firstname}!`,
            duration: 2000,
            closeButton: true,
         });

         if (userType === "teacher") {
            window.location.href = "/teacher"
         } else if (userType === "admin") {
            window.location.href = "/admin"
         } else {
            window.location.href = "/"
         }
      },
   })

   const { mutate: verifyUserTokenMutate } = useMutation(verifyUserToken, {
      onSuccess: ({ message, data }, { code }) => {
         const { user, token, school } = data!;

         sessionStorage.setItem("token", JSON.stringify(token));
         sessionStorage.setItem("user", JSON.stringify(user));
         sessionStorage.setItem("school", JSON.stringify(school));
         setToLocalStorage("code", code);

         showSuccessToast(message || "Login successful!", {
            description: `Welcome back, ${user.firstname}!`,
            duration: 2000,
            closeButton: true,
         });

         if (userType === "teacher") {
            window.location.href = "/teacher"
         } else {
            window.location.href = "/"
         }
      },
   })

   useEffect(() => {
      if (!loginToken) return;

      if (code) {
         verifyUserTokenMutate({ userType, token: loginToken, code });
      } else {
         verifySchoolCubeTokenMutate({ token: loginToken })
      }
   }, [code, loginToken, verifyUserTokenMutate, userType, verifySchoolCubeTokenMutate])


   return (
      <div className="grid min-h-dvh grid-rows-[auto,1fr]">
         <header className="grid h-[5rem] place-items-center border-b border-primary-transparent bg-background">
            <ClassCubeLogo />
         </header>
         <main className="grid h-full place-items-center bg-[#FAFBFC] px-4">
            <Loader />
         </main>
      </div>
   )
}

export default TokenAuth