import { Formik } from "formik";
import { CustomInput, CustomSelect } from "@/components";
import { Button } from "@/components/ui/button";
import { addSingleTeacher, editTeacher, getTeacher } from "@/api/admin";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, ApiResponse } from "@/types/api.types";
import { Loader2Icon, LoaderIcon } from "lucide-react";
import { showSuccessToast } from "@/lib/toast";
import { ITeacher } from "@/types/user.types";
import ShareCredentials from "@/components/ShareCredentials";
import * as Yup from "yup";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useSearchParams } from "react-router-dom";

type AddSingleInstructorProps = {
  onClose?: () => void;
  currentInstructor?: Partial<ITeacher>;
};

export interface InstructorFields {
  lastname: string;
  title: string;
  firstname: string;
  othernames: string;
  gender: string;
  email: string;
  phoneNumber: string;
  password: string;
}

const AddSingleInstructor = ({
  onClose,
  currentInstructor,
}: AddSingleInstructorProps) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const page = parseInt(searchParams.get("page") || "1") || 1;
  const limit = parseInt(searchParams.get("per_page") || "15") || 10;

  const { data: teacher, isLoading: teacherIsLoading } = useQuery(
    ["teachers", currentInstructor?._id || null],
    () => getTeacher(currentInstructor?._id || ""),
    {
      enabled: !!currentInstructor?._id,
    },
  );

  const initialValues = currentInstructor?._id
    ? {
        lastname: teacher?.data?.lastname || "",
        title: teacher?.data?.title || "",
        firstname: teacher?.data?.firstname || "",
        othernames: teacher?.data?.othernames || "",
        gender: teacher?.data?.gender || "",
        email: teacher?.data?.email || "",
        phoneNumber: teacher?.data?.phoneNumber || "",
      }
    : {
        lastname: "",
        title: "",
        firstname: "",
        othernames: "",
        gender: "",
        email: "",
        phoneNumber: "",
        password: "",
      };

  const validationSchema = Yup.object().shape({
    lastname: Yup.string().required("This field is required"),
    title: currentInstructor?._id
      ? Yup.string()
      : Yup.string().required("This field is required"),
    firstname: Yup.string().required("This field is required"),
    othernames: Yup.string(),
    gender: Yup.string().required("This field is required"),
    email: Yup.string().email().required("This field is required"),
    phoneNumber: Yup.string().required("This field is required"),
    password: currentInstructor?._id
      ? Yup.string()
      : Yup.string().required("This field is required"),
  });

  const { mutate, isLoading } = useMutation<
    ApiResponse<Partial<ITeacher | null>>,
    ApiError | Error,
    Partial<Yup.InferType<typeof validationSchema>>
  >({
    mutationFn: currentInstructor?._id
      ? (data) => editTeacher(data, currentInstructor?._id)
      : addSingleTeacher,
    mutationKey: ["teachers", "single"],
    onSuccess: (data) => {
      showSuccessToast(data?.message || "Teacher added", {
        action: !currentInstructor?._id ? (
          <ShareCredentials data={data.data as ITeacher} />
        ) : undefined,
        closeButton: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["teachers", { search, page, limit }],
        exact: !!currentInstructor?._id,
      });
      onClose?.();
    },
  });

  function addTeacher(payload: Partial<InstructorFields>) {
    mutate(payload);
  }

  if (currentInstructor?.id && teacherIsLoading)
    return (
      <Alert>
        <LoaderIcon className="size-4 animate-spin" />
        <AlertTitle>Loading...</AlertTitle>
        <AlertDescription className="text-xs">
          <span className="font-mono">
            Fetching
            {currentInstructor
              ? ` ${currentInstructor?.title || ""} ${currentInstructor?.lastname}'${currentInstructor?.lastname?.endsWith("s") ? " s" : ""}`
              : null}{" "}
            data
          </span>
        </AlertDescription>
      </Alert>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => addTeacher(values as InstructorFields)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form
          className="flex flex-col justify-between gap-5 pt-5"
          onSubmit={handleSubmit}
        >
          <CustomInput
            label="Title"
            id="title"
            value={values.title}
            onChange={handleChange}
            placeholder="Enter title"
            name="title"
          />
          <CustomInput
            label="Surname"
            id="lastname"
            value={values.lastname}
            onChange={handleChange}
            placeholder="Enter surname"
            name="lastname"
          />
          <CustomInput
            label="First Name"
            value={values.firstname}
            id="firstname"
            onChange={handleChange}
            placeholder="Enter first name"
            name="firstname"
          />
          <CustomInput
            label="Middle Name"
            id="othernames"
            value={values.othernames}
            onChange={handleChange}
            placeholder="Enter middle name"
            name="othernames"
          />
          <CustomSelect
            label="Gender"
            value={values.gender}
            options={[
              { label: "Male", value: "male" },
              { label: "Female", value: "female" },
            ]}
            onChange={(val) => setFieldValue("gender", val)}
            placeholder="Select gender"
            name="gender"
          />
          <CustomInput
            label="Email"
            id="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Enter email"
            name="email"
          />
          <CustomInput
            label="Phone"
            id="phoneNumber"
            type="tel"
            value={values.phoneNumber}
            onChange={handleChange}
            placeholder="Enter phone number"
            name="phoneNumber"
          />

          {!currentInstructor?._id && (
            <CustomInput
              label="Password"
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              placeholder="Enter password"
              name="password"
              showPasswordToggle
            />
          )}

          <div className="flex w-full items-center justify-end gap-2">
            <Button
              variant="outline"
              type="button"
              onClick={() => {
                onClose?.();
              }}
            >
              Cancel
            </Button>

            <Button type="submit" className="min-w-[100px]">
              {isLoading ? <Loader2Icon className="animate-spin" /> : "Save"}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddSingleInstructor;
