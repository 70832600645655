import { DialogClose, DialogTrigger } from "@/components/ui/dialog";
import { Formik, useFormikContext } from "formik";
import CustomSelect from "@/components/CustomSelect";
import { classOptions, subjectOptions } from "@/assets/constants";
import { Button, buttonVariants } from "@/components/ui/button";
import * as Yup from "yup";
import { CustomModal } from "@/components";

type Props = {};

const AddGameToLesson = (props: Props) => {
  const initialValues = {
    subject: "",
    class: "",
    game: "",
  };
  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    game: Yup.string().required("This field is required"),
  });

  return (
    <CustomModal
      trigger={
        <DialogTrigger className={buttonVariants({ variant: "outline" })}>
          Add Game
        </DialogTrigger>
      }
      title="Add Game"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => console.log(values)}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="grid gap-4 pb-4">
            <CustomSelect
              options={subjectOptions}
              value={values.subject}
              onChange={(val) => setFieldValue("subject", val)}
              placeholder="Subject"
              name="subject"
              label="Subject"
            />
            <CustomSelect
              options={classOptions}
              value={values.class}
              onChange={(val) => setFieldValue("class", val)}
              placeholder="Class"
              name="class"
              label="Class"
            />
            <GameSelect />
            <div className="flex items-center gap-3 mt-3 ml-auto">
              <DialogClose className={buttonVariants({ variant: "outline" })}>
                Cancel
              </DialogClose>
              <Button>Add Game</Button>
            </div>
          </form>
        )}
      </Formik>
    </CustomModal>
  );
};

const GameSelect = () => {
  const { values, setFieldValue } = useFormikContext();

  if (!(values as any).subject || !(values as any).class) return null;

  return (
    <CustomSelect
      options={classOptions}
      value={(values as any).game}
      onChange={(val) => setFieldValue("game", val)}
      placeholder="Game"
      name="game"
      label="Game"
    />
  );
};

export default AddGameToLesson;
