import api from "./common";
import { PasswordData } from "@/panels/UpdatePassword";
import axios from "axios";
import { ApiResponse } from "@/types/api.types";
import {
  onReqFulfilled,
  onReqRejected,
  onResFulfilled,
  onResRejected,
} from "./helpers";
import { IAdmin, IPrivileges, ITeacherResponse } from "@/types/user.types";
import { IAllocation } from "@/types/subject.types";
import { ITenant } from "@/types/tenants.types";

type TeacherNotAdded = {};

const adminAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASEURL}/admin`,
});

adminAPI.interceptors.request.use(onReqFulfilled, onReqRejected);
adminAPI.interceptors.response.use(onResFulfilled, onResRejected);

export const changeAdminPassword = async (data: Partial<PasswordData>) =>
  api.post("/auth/admin/change-password", data);

export const addAdmin = async (data): Promise<ApiResponse<Partial<IAdmin>>> =>
  api.post("/users/admins", data);

export const editAdmin = async (id: string, data): Promise<ApiResponse<Partial<IAdmin>>> =>
  api.patch(`/users/admins/${id}`, data);

export const getAdminProfile = async (): Promise<ApiResponse<IAdmin>> => adminAPI.get("/profile");

export const updateAdminPrivileges = async (id: string, data): Promise<ApiResponse<{ privileges: IPrivileges | null }>> =>
  api.patch(`/users/admin/${id}/privilege`, data);

// Teachers
export const addSingleTeacher = async (data) => api.post("/users/teachers", data);

export const addBulkTeachers = async (data): Promise<ApiResponse<Partial<{ teachersNotAdded: TeacherNotAdded[] | [] } | null>>> =>
  api.post("/users/teachers/bulk", data, { headers: { "Content-Type": "multipart/form-data" }, });

export const getTeachers = async (params: Record<string, any>) =>
  api.get("/users/teachers", { params });

export const getTeacher = async (id: string): Promise<ApiResponse<Partial<ITeacherResponse>>> =>
  api.get(`/users/teachers/${id}`);

export const editTeacher = async (data, id) => api.patch(`/users/teachers/${id}`, data);

export const disableTeacherAccount = async (id: string): Promise<ApiResponse<null>> => api.patch(`/users/teachers/${id}/disable`);

export const enableTeacherAccount = async (id: string): Promise<ApiResponse<null>> => api.patch(`/users/teachers/${id}/enable`);

export const allocateSubjectToTeacher = async (data: {
  subject: string;
  teacher: string;
  class: string;
}) => adminAPI.post("/subject/allocate", data);

export const getAllocatedClassSubjects = async (params: { classId: string }): Promise<ApiResponse<IAllocation[]>> => adminAPI.get("/subject/allocate", { params });

export const deleteSubjectAllocation = async (allocationId: string) => adminAPI.delete(`/subject/allocate/${allocationId}`);

export const enrolStudentsForSubjects = async (data): Promise<ApiResponse> => adminAPI.post("/enroll-students", data)

export const getAdminDashBoard = () => adminAPI.get("/dashboard")

export const getStudentClassSubjects = (params) => adminAPI.get("/class-subjects", { params })

export const updateAutoApprovalStatus = (data): Promise<ApiResponse<ITenant>> => adminAPI.patch("/lessons/update-approval", data)

export const updateSchoolDetails = (data): Promise<ApiResponse<ITenant>> => adminAPI.patch("/school-details", data)
