import { LogoIcon } from "@/assets/icons";
import { cn } from "@/lib/utils";

type LogoProps = {
  className?: string;
  collapsed?: boolean;
};

const ClassCubeLogo = ({ className, collapsed }: LogoProps) => {
  return (
    <span
      className={cn(
        "inline-flex cursor-default items-center gap-0.5",
        className,
      )}
    >
      <LogoIcon />
      {!collapsed && (
        <span className="-mt-px inline-block text-[1.625rem] font-light leading-none -tracking-[0.08em] text-[currentColor]">
          Class<span className="font-medium">Cube</span>
        </span>
      )}
    </span>
  );
};

export default ClassCubeLogo;
