import * as Yup from "yup";
import { Formik } from "formik";
import { Loader2Icon, LoaderIcon } from "lucide-react";
import { type TableColumn } from "react-data-table-component";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { CustomDataTable, CustomInput, CustomSelect } from "@/components";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { IAllocation, ISubject } from "@/types/subject.types";
import {
  allocateSubjectToTeacher,
  getTeacher,
  deleteSubjectAllocation,
} from "@/api/admin";
import { Button } from "@/components/ui/button";
import { ApiResponse } from "@/types/api.types";
import { showSuccessToast } from "@/lib/toast";
import { ITeacher } from "@/types/user.types";
import { TrashIcon } from "@/assets/icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useState } from "react";

type InstructorAllocationsProps = {
  currentInstructor: Partial<ITeacher>;
  onClose?: () => void;
};

interface DataRow extends IAllocation {
  actions?: unknown;
}

const columns: TableColumn<DataRow>[] = [
  { name: "S/N", selector: (_, index = 0) => index + 1, width: "90px" },
  {
    name: "Subject",
    cell: (row) =>
      typeof row.subject === "string" ? row.subject : row.subject.title,
    width: "200px",
  },
  {
    name: "Class",
    cell: (row) => (
      <span className="capitalize">
        {typeof row.class === "string" ? row.class : row.class.name}
      </span>
    ),
  },
  {
    name: "Actions",
    cell: (row) => {
      return <DeleteAllocation row={row} />;
    },
  },
];

const InstructorAllocations = ({
  currentInstructor,
  onClose,
}: InstructorAllocationsProps) => {
  const { subjects, classes } = useMainLayoutContext();
  const queryClient = useQueryClient();

  const subjectOptions =
    subjects?.map((subject) => ({
      value: subject._id,
      label: subject.title,
    })) || [];
  const classOptions =
    classes?.map((classItem) => ({
      value: classItem._id,
      label: classItem.name,
    })) || [];

  const {
    data: instructor,
    isLoading: instructorIsLoading,
    refetch: refetchInstructor,
    isFetching: fetchingInstructor,
  } = useQuery(
    ["teachers", currentInstructor?._id || null],
    () => getTeacher(currentInstructor?._id || ""),
    {
      enabled: !!currentInstructor?._id,
    },
  );

  const initialValues = {
    teacher: currentInstructor?._id || "",
    class: "",
    subject: "",
  };
  const validationSchema = Yup.object().shape({
    teacher: Yup.string().required("Please select a teacher"),
    class: Yup.string().required("Please select a class"),
    subject: Yup.string().required("Please select a subject"),
  });

  const data: IAllocation[] = instructor?.data?.allocations || [];

  const { mutate: allocate, isLoading: allocating } = useMutation({
    mutationFn: allocateSubjectToTeacher,
    mutationKey: ["teachers", "single"],
    onSuccess: (data: ApiResponse<Partial<any | null>>) => {
      showSuccessToast(
        data?.message || `Subject allocated to ${currentInstructor?.firstname}`,
      );
      queryClient.invalidateQueries({
        queryKey: ["teachers", currentInstructor?._id || null],
        exact: true,
      });
    },
  });

  function allocateToTeacher(payload: typeof initialValues) {
    allocate(payload);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={allocateToTeacher}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <div className="flex h-full flex-grow flex-col gap-8.5">
          <form onSubmit={handleSubmit} className="grid gap-6 pt-6">
            <div className="grid gap-5 md:grid-cols-2">
              <CustomInput
                label="First Name"
                id="firstname"
                value={currentInstructor?.firstname || ""}
                disabled
              />
              <CustomInput
                label="Last Name"
                id="lastname"
                value={currentInstructor?.lastname || ""}
                disabled
              />
              <CustomSelect
                label="Class"
                className="h-12"
                options={classOptions}
                value={values.class}
                name="class"
                onChange={(val) => setFieldValue("class", val)}
                placeholder="Select class"
              />
              <CustomSelect
                options={subjectOptions}
                className="h-12"
                value={values.subject}
                name="subject"
                onChange={(val) => setFieldValue("subject", val)}
                placeholder="Select subject"
                label="Subject"
              />
            </div>

            <Button
              type="submit"
              className="ml-auto h-8.5 min-w-[100px] max-w-[139px] rounded-lg"
              disabled={allocating}
            >
              {allocating ? (
                <Loader2Icon className="size-4 animate-spin" />
              ) : (
                "Save Allocation"
              )}
            </Button>
          </form>

          <CustomDataTable
            data={data}
            columns={columns}
            emptyStateMessage={
              fetchingInstructor ? (
                "Loading..."
              ) : (
                <p>
                  No allocations.{" "}
                  <button
                    className="text-primary hover:underline"
                    type="button"
                    onClick={() => refetchInstructor()}
                  >
                    Reload
                  </button>
                  ?
                </p>
              )
            }
            paginationTotalRows={instructor?.data?.allocations?.length || 0}
            progressComponent={
              <Alert>
                <LoaderIcon className="size-4 animate-spin" />
                <AlertTitle>Loading...</AlertTitle>
                <AlertDescription className="text-xs">
                  <span className="font-mono">
                    Fetching
                    {currentInstructor
                      ? ` ${currentInstructor?.title || ""} ${currentInstructor?.lastname}${currentInstructor?.lastname?.toLowerCase()?.endsWith("s") ? "'" : "'s"}`
                      : null}{" "}
                    data
                  </span>
                </AlertDescription>
              </Alert>
            }
            progressPending={instructorIsLoading}
          />
        </div>
      )}
    </Formik>
  );
};

function DeleteAllocation({ row }: { row: DataRow }) {
  const [confirming, setConfirming] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: deleteAllocation, isLoading: deletingAllocation } =
    useMutation({
      mutationFn: deleteSubjectAllocation,
      mutationKey: ["teachers", row.teacher || null],
      onSuccess: (data: ApiResponse<null>) => {
        showSuccessToast(data.message || "Subject allocation removed", {
          dismissible: true,
        });
        queryClient.invalidateQueries({
          queryKey: ["teachers", row.teacher || null],
          exact: true,
        });
        setConfirming(false);
      },
    });

  function handleDeleteAllocation() {
    deleteAllocation(row?._id);
  }

  return (
    <AlertDialog open={confirming}>
      <AlertDialogTrigger onClick={() => setConfirming(true)} asChild>
        <Button
          type="submit"
          disabled={deletingAllocation}
          variant="custom"
          className="space-x-2 text-destructive"
        >
          {deletingAllocation ? (
            "Deleting..."
          ) : (
            <>
              <TrashIcon /> <span>Delete</span>
            </>
          )}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            You are about to delete <b>{(row.subject as ISubject).title}</b>{" "}
            from this teacher's allocations. This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => setConfirming(false)}
            className="border-border text-foreground"
          >
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            className="min-w-16 bg-destructive text-destructive-foreground"
            onClick={handleDeleteAllocation}
            disabled={deletingAllocation}
          >
            {deletingAllocation ? (
              <Loader2Icon className="size-4 animate-spin" />
            ) : (
              "Delete"
            )}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default InstructorAllocations;
