import {
  CustomDateInput,
  CustomEditor,
  CustomInput,
  Dropzone,
  SubjectAndClassSelect,
} from "@/components";
import { Switch } from "@/components/ui/switch";
import { cn, convertToEditorState } from "@/lib/utils";
import { ILesson } from "@/types/lesson.types";
import { sub } from "date-fns";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";

type Props = {
  lesson?: ILesson;
};

const SimilarFields = ({ lesson }: Props) => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { pathname } = useLocation();

  const user = pathname.startsWith("/teacher") ? "teacher" : "admin";

  return (
    <>
      {!lesson && (
        <>
          <CustomInput
            label="Assignment Title"
            value={(values as any).title}
            onChange={handleChange}
            placeholder="Enter assignment title"
            name="title"
          />
          <div
            className={cn(
              "grid gap-4",
              user === "admin" && "@[350px]/sheet:grid-cols-2",
            )}
          >
            <SubjectAndClassSelect
              subjectValue={(values as any).subject}
              subjectOnChange={(val) => setFieldValue("subject", val)}
              classOnChange={(val) => setFieldValue("class", val)}
              classValue={(values as any).class}
              key={`${(values as any).subject}-${(values as any).class}`}
              edit
            />
          </div>
        </>
      )}
      <CustomEditor
        label="Assignment Question(s)"
        setState={(editorState) => setFieldValue("content", editorState)}
        defaultEditorState={convertToEditorState((values as any).content ?? "")}
      />
      <Dropzone name="attachments" multiple />
      <div className="flex items-center gap-1 my-1">
        <Switch
          id="canSubmitAfterDeadline"
          className="h-[16px] w-[24px] *:h-[12px] *:w-[12px] *:rounded-full *:data-[state=checked]:translate-x-[80%]"
          checked={(values as any).canSubmitAfterDeadline}
          onCheckedChange={(checked) =>
            setFieldValue("canSubmitAfterDeadline", checked)
          }
        />
        <label
          htmlFor="canSubmitAfterDeadline"
          className="text-sm text-[#52565F]"
        >
          Student should be able to submit assignment after due date
        </label>
      </div>
      <CustomDateInput
        label="Due Date"
        placeholder="Enter due date"
        selected={(values as any).dueDate}
        onSelect={(date) => setFieldValue("dueDate", date)}
        timeFormat="dd MMM yyyy - hh:mm aa"
        name="dueDate"
        mode="single"
        disabled={(date) => date < sub(new Date(), { days: 1 })}
        showDatePicker
      />
    </>
  );
};

export default SimilarFields;
