import * as Yup from "yup";
import { Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addSingleStudent, editStudent } from "@/api/students";
import { CustomInput, CustomSelect } from "@/components";
import { Button } from "@/components/ui/button";
import { showSuccessToast } from "@/lib/toast";
import { IStudent } from "@/types/user.types";
import { ApiError, ApiResponse } from "@/types/api.types";
import { Loader2Icon } from "lucide-react";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { IClass } from "@/types/class.types";
import { useLayout } from "@/hooks/useLayout";

type AddSingleStudentProps = {
  currentStudent?: IStudent;
  onClose?: () => void;
};

const AddSingleStudent = ({
  currentStudent,
  onClose,
}: AddSingleStudentProps) => {
  const layout = useLayout();
  const queryClient = useQueryClient();
  const { classes, currentTermId, allocations } = useMainLayoutContext();
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const page = parseInt(searchParams.get("page") || "1") || 1;
  const limit = parseInt(searchParams.get("per_page") || "15") || 10;

  const currentTerm = currentTermId;

  const allClassesOptions =
    classes?.map((classItem) => ({
      value: classItem._id,
      label: classItem.name,
    })) || [];

  const allocationOptions =
    allocations?.map((allocation) => ({
      value: (allocation.class as IClass)._id,
      label: (allocation.class as IClass).name,
    })) || [];

  const initialValues = currentStudent
    ? {
        firstname: currentStudent.student?.firstname || "",
        lastname: currentStudent.student?.lastname || "",
        othernames: currentStudent.student?.othernames || "",
        email: currentStudent.student?.email || "",
        phoneNumber: currentStudent.student?.phoneNumber || "",
        gender: currentStudent.student?.gender || "",
        classId: (currentStudent.class as IClass)._id || "",
        termId: currentTerm,
      }
    : {
        firstname: "",
        lastname: "",
        othernames: "",
        classId: "",
        email: "",
        gender: "",
        phoneNumber: "",
        termId: currentTerm,
      };

  const validationSchema = Yup.object().shape({
    lastname: Yup.string().required("Student's lastname is required"),
    firstname: Yup.string().required("Student's firstname is required"),
    othernames: Yup.string(),
    classId: Yup.string().required("Select student's class"),
    gender: Yup.string()
      .required("Student's gender is required")
      .oneOf(["male", "female"], "Gender must be either male or female"),
    email: Yup.string().email().required("Student's email is required"),
    phoneNumber: Yup.string(),
  });

  const { mutate, isLoading } = useMutation<
    ApiResponse<Partial<IStudent | null>>,
    ApiError | Error,
    Partial<Yup.InferType<typeof validationSchema>>
  >({
    mutationFn: currentStudent?._id
      ? (data) => editStudent(data, currentStudent?._id)
      : addSingleStudent,
    mutationKey: ["students", "single"],
    onSuccess: (data) => {
      showSuccessToast(data?.message || "Student added", {
        closeButton: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["students", { search, page, limit }],
        exact: !!currentStudent?._id,
      });
      onClose?.();
    },
  });

  function onSubmit(payload: Partial<Yup.InferType<typeof validationSchema>>) {
    mutate(payload);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form
          className="flex flex-col justify-between gap-4 pt-6"
          onSubmit={handleSubmit}
        >
          <CustomInput
            label="Surname"
            id="lastname"
            value={values.lastname}
            onChange={handleChange}
            placeholder="Enter surname"
            name="lastname"
          />
          <CustomInput
            label="First Name"
            value={values.firstname}
            id="firstname"
            onChange={handleChange}
            placeholder="Enter first name"
            name="firstname"
          />
          <CustomInput
            label="Other Name"
            id="othernames"
            value={values.othernames}
            onChange={handleChange}
            placeholder="Enter other names"
            name="othernames"
          />
          <CustomSelect
            label="Gender"
            value={values.gender}
            options={[
              { label: "Male", value: "male" },
              { label: "Female", value: "female" },
            ]}
            onChange={(val) => setFieldValue("gender", val)}
            placeholder="Select gender"
            name="gender"
          />
          <CustomSelect
            label="Class"
            options={layout === "admin" ? allClassesOptions : allocationOptions}
            onChange={(val) => setFieldValue("classId", val)}
            placeholder="Select class"
            id="classId"
            name="classId"
          />
          <CustomInput
            label="Email"
            id="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Enter email"
            name="email"
          />
          <CustomInput
            label="Phone"
            id="phoneNumber"
            type="tel"
            value={values.phoneNumber}
            onChange={handleChange}
            placeholder="Enter phone number"
            name="phoneNumber"
          />

          <div className="flex w-full items-center justify-end gap-2">
            <Button
              variant="outline"
              type="button"
              onClick={() => {
                onClose?.();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="min-w-[100px]">
              {isLoading ? <Loader2Icon className="animate-spin" /> : "Save"}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddSingleStudent;
