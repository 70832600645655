import { Formik } from "formik";
import { Button, buttonVariants } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { CustomInput, CustomSelect } from "@/components";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSubject, updateSubject } from "@/api/common";
import { showSuccessToast } from "@/lib/toast";
import { ISubject } from "@/types/subject.types";

type AddSubjectProps = {
  closeModal: () => void;
  subject?: ISubject;
};

const AddSubject = ({ closeModal, subject }: AddSubjectProps) => {
  const queryClient = useQueryClient();

  const initialValues = {
    id: subject?._id ?? "",
    title: subject?.title ?? "",
    abbreviation: subject?.abbreviation ?? "",
    type: subject?.type ?? "",
  };
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    title: Yup.string().required("This field is required"),
    abbreviation: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
  });

  const { mutate, isLoading } = useMutation(
    !!subject ? updateSubject : createSubject,
    {
      onSuccess: ({ message }) => {
        showSuccessToast(message, { closeButton: true });
        queryClient.invalidateQueries({
          queryKey: ["subjects-paginated"],
          type: "active",
        });
        closeModal();
      },
    },
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form
          className="flex flex-col justify-between flex-grow h-full gap-6 pb-8"
          onSubmit={handleSubmit}
        >
          <div className="py-4 space-y-5">
            <CustomInput
              label="Subject name"
              id="title"
              name="title"
              placeholder="Enter Subject Name"
              value={values.title}
              onChange={handleChange}
            />
            <CustomInput
              label="Abbreviation"
              id="abbreviation"
              name="abbreviation"
              placeholder="Enter Subject abbreviation"
              value={values.abbreviation}
              onChange={handleChange}
            />
            <CustomSelect
              options={[
                { value: "compulsory", label: "Compulsory" },
                { value: "elective", label: "Elective" },
              ]}
              value={values.type}
              onChange={(val) => setFieldValue("type", val)}
              placeholder="Type"
              label="Type"
              name="type"
            />
          </div>
          <div className="flex items-center justify-end w-full gap-2">
            <DialogClose
              type="button"
              className={buttonVariants({ variant: "outline" })}
            >
              Cancel
            </DialogClose>
            <Button disabled={isLoading} className="min-w-[100px]">
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddSubject;
