import {
  createAssignment,
  getAllocationStudents,
  getAssignmentDetails,
  updateAssignment,
} from "@/api/common";
import { AddIcon, CloseIcon } from "@/assets/icons";
import {
  CustomCheckBox,
  CustomInput,
  CustomSelect,
} from "@/components";
import { Button } from "@/components/ui/button";
import { MultiSelect } from "@/components/ui/shad-multi-select";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { useLayout } from "@/hooks/useLayout";
import {
  removeEmptyOrNull,
  removeItemAtIndex,
  showSuccessToast,
  studentsPerGroupRange,
} from "@/lib/utils";
import { IAssignment } from "@/types/assignments.types";
import { IClass } from "@/types/class.types";
import { ILesson } from "@/types/lesson.types";
import { ISubject } from "@/types/subject.types";
import { User } from "@/types/user.types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, formatISO, parseISO, sub } from "date-fns";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import SimilarFields from "./SimilarFields";

type CreateProps = {
  lesson?: ILesson;
  closeModal: () => void;
};
type EditProps = {
  assignment: IAssignment;
  closeModal: () => void;
};

const sampleGroup = { name: "", students: [] };

const AddGroupAssignment = ({ lesson, closeModal }: CreateProps) => {
  const { currentTermId } = useMainLayoutContext();
  const queryClient = useQueryClient();

  const initialValues = {
    lessonNote: lesson?._id ?? "",
    title: lesson?.topic ?? "",
    content: "",
    class: (lesson?.class as IClass)?._id ?? "",
    subject: (lesson?.subject as ISubject)?._id ?? "",
    term: currentTermId,
    canSubmitAfterDeadline: false,
    attachments: [],
    dueDate: new Date(),
    addAutomatically: false,
    groups: [sampleGroup],
    noOfGroups: "",
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
    dueDate: Yup.date().min(
      sub(new Date(), { days: 1 }),
      "Due date cannot be before today",
    ),
    groups: Yup.array().when("addAutomatically", {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema.of(
          Yup.object().shape({
            name: Yup.string().required("This field is required"),
            students: Yup.array().min(
              2,
              "At least 2 students must be in a group",
            ),
          }),
        ),
    }),
    noOfGroups: Yup.number().when("addAutomatically", {
      is: true,
      then: (schema) =>
        schema
          .min(2, "Minimum number of groups is 2")
          .required("This field is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const { mutate, isLoading } = useMutation(createAssignment, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.invalidateQueries({
        queryKey: ["lesson"],
      });
      queryClient.invalidateQueries({
        queryKey: ["assignment"],
      });
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ dueDate, ...rest }) =>
        mutate(
          removeEmptyOrNull({
            ...rest,
            dueDate: formatISO(dueDate),
            isGroup: true,
          }),
        )
      }
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-4">
          <CustomCheckBox
            label="Add Automatically"
            name="addAutomatically"
            id="addAutomatically"
            type="checkbox"
            onChange={(event) => {
              handleChange(event);
              if (event.target.checked) {
                setFieldValue("groups", null);
                setFieldValue("noOfGroups", "2");
              } else {
                setFieldValue("groups", [sampleGroup]);
                setFieldValue("noOfGroups", null);
              }
            }}
            checked={values.addAutomatically}
          />
          <SimilarFields lesson={lesson} />
          <AssignmentGrouping />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export const EditGroupAssignment = ({ assignment, closeModal }: EditProps) => {
  const { currentTermId } = useMainLayoutContext();
  const queryClient = useQueryClient();

  const { data: rawAssignment } = useQuery({
    queryKey: ["assignment", assignment?._id as string],
    queryFn: () => getAssignmentDetails(assignment?._id as string),
    suspense: true,
  });

  const assignmentDetails = rawAssignment?.data!;

  const initialValues = {
    id: assignment?._id,
    title: assignmentDetails?.title ?? "",
    content: assignmentDetails?.content ?? "",
    class: (assignmentDetails?.class as IClass)?._id ?? "",
    subject: (assignmentDetails?.subject as ISubject)?._id ?? "",
    term: currentTermId,
    canSubmitAfterDeadline: assignmentDetails?.canSubmitAfterDeadline,
    attachments: [],
    dueDate: format(parseISO(assignmentDetails?.dueDate), "yyyy-MM-dd"),
    addAutomatically: false,
    groups: assignmentDetails?.groups?.map((group) => ({
      name: group.name,
      students: group.students.map((student) => student._id),
    })),
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
    dueDate: Yup.date().min(
      sub(new Date(), { days: 1 }),
      "Due date cannot be before today",
    ),
    groups: Yup.array().when("addAutomatically", {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema.of(
          Yup.object().shape({
            name: Yup.string().required("This field is required"),
            students: Yup.array().min(
              2,
              "At least 2 students must be in a group",
            ),
          }),
        ),
    }),
    noOfGroups: Yup.number().when("addAutomatically", {
      is: true,
      then: (schema) =>
        schema
          .min(2, "Minimum number of groups is 2")
          .required("This field is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const { mutate, isLoading } = useMutation(updateAssignment, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.invalidateQueries({
        queryKey: ["lesson"],
      });
      queryClient.invalidateQueries({
        queryKey: ["assignment"],
      });
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ dueDate, ...rest }) =>
        mutate(
          removeEmptyOrNull({
            ...rest,
            dueDate: formatISO(dueDate),
            isGroup: true,
          }) as any,
        )
      }
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="grid gap-4">
          <CustomCheckBox
            label="Add Automatically"
            name="addAutomatically"
            id="addAutomatically"
            type="checkbox"
            onChange={(event) => {
              handleChange(event);
              if (event.target.checked) {
                setFieldValue("groups", null);
                setFieldValue("noOfGroups", "2");
              } else {
                setFieldValue("groups", [sampleGroup]);
                setFieldValue("noOfGroups", null);
              }
            }}
            checked={values.addAutomatically}
          />
          <SimilarFields />
          <AssignmentGrouping />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const AssignmentGrouping = () => {
  const { currentTermId } = useMainLayoutContext();
  const layout = useLayout();
  const { values, setFieldValue } = useFormikContext();

  const subjectId = (values as any)?.subject;
  const classId = (values as any)?.class;

  const { data } = useQuery({
    queryKey: ["allocation-students", subjectId, classId, currentTermId],
    queryFn: () =>
      getAllocationStudents(layout)({
        limit: 1000,
        subjectId,
        classId,
        termId: currentTermId,
      }),
    enabled: !!subjectId && !!classId,
    suspense: true,
  });

  const students = data?.data?.docs?.map(doc => doc.student) ?? [];
  const numberOfStudents = data?.data?.totalDocs! ?? 0;

  const addGroup = () =>
    setFieldValue("groups", [...(values as any).groups, sampleGroup]);

  if ((values as any).addAutomatically)
    return (
      <div className="grid gap-1">
        <CustomSelect
          options={Array(15)
            .fill("")
            .map((_, index) => ({
              label: (index + 2).toString(),
              value: (index + 2).toString(),
            }))}
          value={(values as any).noOfGroups}
          onChange={(val) => setFieldValue("noOfGroups", val)}
          placeholder="Number of groups"
          label="Number of groups"
        />
        <p className="text-sm font-medium text-[#737D8F]">
          There are {numberOfStudents} total students in this class. Groups will have
          {studentsPerGroupRange(numberOfStudents, (values as any).noOfGroups || 1,)}
          students each
        </p>
      </div>
    );

  return (
    <div className="grid gap-6 rounded-lg border border-[#E5E9F2] px-5 py-6">
      {(values as any).groups?.map((_, index) => (
        <Group key={index} index={index} students={students} />
      ))}
      <Button
        variant="ghost"
        type="button"
        className="w-fit text-primary hover:text-primary"
        onClick={addGroup}
      >
        <AddIcon />
        Add Group
      </Button>
    </div>
  );
};

type GroupProps = {
  index: number;
  students: User[];
};

const Group = ({ index, students }: GroupProps) => {
  const { values, handleChange, setFieldValue } = useFormikContext();

  return (
    <div className="grid gap-3 rounded-lg border border-[#E5E9F2] px-4 pb-5 pt-1">
      <Button
        type="button"
        variant="ghost"
        className="gap-1 ml-auto text-xs text-red-500"
        onClick={() =>
          setFieldValue(
            "groups",
            removeItemAtIndex((values as any).groups, index),
          )
        }
      >
        <CloseIcon className="h-[16px] w-[16px]" /> Remove
      </Button>
      <CustomInput
        label="Group Title"
        value={(values as any)?.groups?.at(index)?.name}
        onChange={handleChange}
        placeholder={`Group ${index + 1}`}
        name={`groups[${index}].name`}
        labelClassName=""
      />
      <MultiSelect
        options={students?.map((student) => ({
          label: student.firstname.concat(" ", student.lastname),
          value: student._id.toString(),
        }))}
        placeholder="Select students"
        onValueChange={(value) => setFieldValue(`groups[${index}].students`, value)}
      />
    </div>
  );
};

export default AddGroupAssignment;
