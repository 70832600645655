import { cn } from "@/lib/utils";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { ChangeEventHandler, useState } from "react";
import { Button } from "./ui/button";
import { Calendar, CalendarProps } from "./ui/calendar";
import { setHours, setMinutes } from "date-fns";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { ErrorMessage } from "formik";

type Props = CalendarProps & {
  placeholder: string;
  selected: Date | null;
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  innerContainerClassName?: string;
  name?: string;
  showDatePicker?: boolean;
  timeFormat?: string;
};

const CustomDateInput = ({
  selected,
  placeholder,
  label,
  labelClassName,
  containerClassName,
  timeFormat,
  showDatePicker,
  ...props
}: Props) => {
  const [timeValue, setTimeValue] = useState<string>(
    !!selected ? format(selected, "HH:mm") : "00:00",
  );

  const handleTimeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const time = e.target.value;
    if (!selected) {
      setTimeValue(time);
      return;
    }
    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10));
    const newSelectedDate = setHours(setMinutes(selected, minutes), hours);
    props["onSelect"](newSelectedDate);
    setTimeValue(time);
  };

  return (
    <div className={containerClassName}>
      {!!label && (
        <label
          htmlFor={props["id"]}
          className={cn(
            "mb-1 block text-sm font-medium text-[#495364]",
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="custom"
            className={cn(
              "h-auto w-full border border-[#EAEBF0] px-5 py-3 text-sm has-[:focus]:border-primary has-[:disabled]:bg-[#FBFBFB]",
            )}
          >
            {selected ? (
              format(selected, timeFormat ?? "PPP")
            ) : (
              <span>{placeholder}</span>
            )}
            <CalendarIcon className="ml-auto h-4 w-4 text-[#2C323C]" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar {...props} />
          {!!showDatePicker && (
            <input
              type="time"
              value={timeValue}
              onChange={handleTimeChange}
              className="px-6 py-3"
            />
          )}
        </PopoverContent>
      </Popover>
      {!!props["name"] && (
        <ErrorMessage
          name={props["name"]}
          component="div"
          className="block mt-1 text-xs text-red-500"
        />
      )}
    </div>
  );
};

export default CustomDateInput;
