import { Formik } from "formik";
import { Button, buttonVariants } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { CustomInput } from "@/components";
import { IClassLevel } from "@/types/classLevels.types";
import * as Yup from "yup";
import { showSuccessToast } from "@/lib/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createClassLevel, updateClassLevel } from "@/api/common";

type EditLevelProps = {
  closeModal: () => void;
  classLevel?: IClassLevel;
};

const EditLevel = ({ closeModal, classLevel }: EditLevelProps) => {
  const queryClient = useQueryClient();

  const initialValues = {
    id: classLevel?._id ?? "",
    name: classLevel?.name ?? "",
  };
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required("This field is required"),
  });

  const { mutate, isLoading } = useMutation(
    !!classLevel ? updateClassLevel : createClassLevel,
    {
      onSuccess: ({ message }) => {
        showSuccessToast(message, { closeButton: true });
        queryClient.invalidateQueries({
          queryKey: ["class-levels"],
          type: "active",
        });
        !!classLevel &&
          queryClient.invalidateQueries({
            queryKey: ["classes-paginated"],
            type: "active",
          });
        closeModal();
      },
    },
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => mutate(values)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form
          className="flex flex-col justify-between flex-grow h-full gap-6 pb-8"
          onSubmit={handleSubmit}
        >
          <CustomInput
            label="Subject name"
            id="name"
            name="name"
            placeholder="Enter Subject Name"
            value={values.name}
            onChange={handleChange}
          />
          <div className="flex items-center justify-end w-full gap-2">
            <DialogClose
              type="button"
              className={buttonVariants({ variant: "outline" })}
            >
              Cancel
            </DialogClose>
            <Button disabled={isLoading} className="min-w-[100px]">
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default EditLevel;
