import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { type TableColumn } from "react-data-table-component";
import { ApproveIcon, AvatarIcon, EditIcon, EllipsisIcon, EyeIcon, RejectIcon } from "@/assets/icons";
import {
  BreadCrumbs,
  CustomDataTable,
  CustomPopover,
  CustomSheet,
  EmptyState,
  RouteLink,
} from "@/components";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { AddSingleLesson } from "@/panels";
import { getStatusClassName, showSuccessToast } from "@/lib/utils";
import { ILesson } from "@/types/lesson.types";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getTeacherLessons } from "@/api/teachers";
import useAuth from "@/hooks/useAuth";
import { Loader2Icon } from "lucide-react";
import { ITeacher } from "@/types/user.types";
import { approveLesson, rejectLesson } from "@/api/common";

interface DataRow extends Partial<ILesson> {
  actions?: unknown;
}

const SubjectLessonsPage = () => {
  const { subjectId } = useParams();
  const queryClient = useQueryClient();
  const { user, school } = useAuth();
  const [searchParams] = useSearchParams();
  const { currentTermId } = useMainLayoutContext();
  const { state } = useLocation();

  const search = searchParams.get("search") || "";
  const page = parseInt(searchParams.get("page") || "1") || 1;
  const limit = parseInt(searchParams.get("per_page") || "15") || 10;

  const teacher = user as ITeacher;

  const {
    data: lessons,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "subjects",
      {
        page,
        limit,
        search,
        subjectId,
        termId: currentTermId,
        classId: state?.class?.id || "",
      },
    ],
    queryFn: () =>
      getTeacherLessons({
        page,
        limit,
        subjectId,
        search,
        termId: currentTermId,
      }),
    select: ({ data }) => data?.docs,
  });

  const { mutate: approveLessonMutate } = useMutation(approveLesson, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({
        queryKey: ["subjects"],
        type: "active",
      });
    },
  });
  const { mutate: rejectLessonMutate } = useMutation(rejectLesson, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.refetchQueries({
        queryKey: ["subjects"],
        type: "active",
      });
    },
  });

  const columns: TableColumn<DataRow>[] = [
    { name: "S/N", selector: (_, index = 0) => index + 1, width: "60px" },
    { name: "Lesson Title", cell: (row) => (row as ILesson).topic, grow: 2.5 },
    { name: "Week", cell: (row) => row.week },
    {
      name: "Status",
      cell: (row) => (
        <span className={getStatusClassName(row.status!)}>{row.status}</span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <CustomPopover
            align="end"
            trigger={
              <span>
                <EllipsisIcon />
              </span>
            }
            actions={[
              ...((school?.automaticallyApproveLesson)
                ? [
                  {
                    label: (
                      <div className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-primary-transparent hover:text-primary">
                        <ApproveIcon className="h-[18px] w-[18px]" />
                        Approve
                      </div>
                    ),
                    onClick: () => approveLessonMutate({ id: row?._id }),
                  },
                  {
                    label: (
                      <div className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-destructive/10 hover:text-destructive">
                        <RejectIcon className="h-[18px] w-[18px]" />
                        Reject
                      </div>
                    ),
                    onClick: () => rejectLessonMutate({ id: row?._id }),
                  },
                ]
                : []),
              {
                label: (
                  <RouteLink
                    to={`/lessons/${row._id}`}
                    className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-primary-transparent hover:text-primary"
                  >
                    <EyeIcon className="size-4.5 flex-shrink-0" />
                    View Lesson
                  </RouteLink>
                ),
              },
              {
                label: (
                  <CustomSheet
                    trigger={
                      <div className="flex items-center gap-2 text-sm font-medium text-[#404040] hover:bg-primary-transparent hover:text-primary">
                        <EditIcon className="h-[18px] w-[18px]" />
                        Edit Lesson Details
                      </div>
                    }
                    title="Edit Lesson"
                  >
                    {(closeModal) => (
                      <AddSingleLesson closeModal={closeModal} />
                    )}
                  </CustomSheet>
                ),
              },
            ]}
          />
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <>
      <BreadCrumbs />
      <div className="space-y-10">
        <div className="flex flex-wrap items-center justify-between mb-6">
          <h1 className="page-header">{state.subject?.title} Lessons</h1>
          <CustomSheet trigger={<Button>Add Lesson</Button>} title="Add Lesson">
            {(closeModal) => <AddSingleLesson closeModal={closeModal} />}
          </CustomSheet>
        </div>
        <Card>
          <CardContent className="flex items-center space-x-6 divide-x-2 p-6 [&_>_*]:flex-1 [&_>_*_+_*]:px-6">
            <div className="space-y-2 font-medium">
              <h3 className="text-sm text-[#737D8F]">Subject:</h3>
              <p className="font-display text-header">{state.subject?.title}</p>
            </div>

            <div className="space-y-2 font-medium">
              <h3 className="text-sm text-[#737D8F]">Class:</h3>
              <p className="font-display text-header">{state.class?.name}</p>
            </div>

            <div className="space-y-2 font-medium">
              <h3 className="text-sm text-[#737D8F]">Teacher Assigned:</h3>
              <div className="flex items-center gap-3">
                {user?.photo ? (
                  <img
                    src={user.photo}
                    alt={user.firstname}
                    className="object-cover text-xs border-2 rounded-full bg-secondary"
                  />
                ) : (
                  <div className="inline-grid items-end justify-center overflow-hidden border-2 rounded-full size-6 bg-secondary">
                    <AvatarIcon className="mt-1.5 size-4 text-[#CBD5E1]" />
                  </div>
                )}

                <p className="font-medium text-header">
                  <span>
                    {`${teacher?.title ? `${teacher.title} ` : ""}${teacher.firstname}${teacher.othernames ? ` ${teacher.othernames} ` : ""}`}{" "}
                    <span className="uppercase">{teacher.lastname}</span>
                  </span>
                </p>
              </div>
            </div>
          </CardContent>
        </Card>

        <CustomDataTable
          data={lessons ?? []}
          columns={columns}
          emptyStateMessage={
            isLoading ? (
              <Loader2Icon className="size-6 animate-spin" />
            ) : (
              <EmptyState>
                No lessons found.{" "}
                <button
                  onClick={() => refetch()}
                  className="text-primary hover:underline"
                >
                  Reload
                </button>
                ?
              </EmptyState>
            )
          }
          progressPending={isLoading}
        />
      </div>
    </>
  );
};

export default SubjectLessonsPage;
