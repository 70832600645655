import api from "./common";
import axios, { type AxiosResponse } from "axios";
import {
  onReqFulfilled,
  onReqRejected,
  onResFulfilled,
  onResRejected,
} from "./helpers";
import { ApiResponse, PaginatedResponse } from "@/types/api.types";
import { IStudent, User } from "@/types/user.types";
import { ILesson } from "@/types/lesson.types";
import { IAssignment } from "@/types/assignments.types";
import { ISubject } from "@/types/subject.types";
import { IAnnouncement } from "@/types/announcements.types";

type StudentNotAdded = {};

const studentAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASEURL}/student`,
});

studentAPI.interceptors.request.use(onReqFulfilled, onReqRejected);
studentAPI.interceptors.response.use(onResFulfilled, onResRejected);

export const changeStudentPassword = async (data: {
  newPassword: string;
  oldPassword: string;
}): Promise<AxiosResponse<Partial<User>>> => api.post("/auth/student/change-password", data);

export const addSingleStudent = async (data) => api.post("/users/students", data);

export const addBulkStudents = async (data): Promise<ApiResponse<Partial<{ studentsNotAdded: StudentNotAdded[] | [] } | null>>> =>
  api.post("/users/students/bulk", data, { headers: { "Content-Type": "multipart/form-data" } });

export const getStudents = async (params: Record<string, any>) => api.get("/users/students", { params });

export const getStudent = async (id: string): Promise<ApiResponse<Partial<IStudent>>> => api.get(`/users/students/${id}`);

export const editStudent = async (data, id) => api.patch(`/users/students/${id}`, data);

export const disableStudentAccount = async (id: string): Promise<ApiResponse<null>> => api.patch(`/users/students/${id}/disable`);

export const enableStudentAccount = async (id: string): Promise<ApiResponse<null>> => api.patch(`/users/students/${id}/enable`);

export const getStudentDashboard =
  async (params: Record<string, any>): Promise<
    ApiResponse<{
      uploadedLessons: ILesson[];
      assignments: IAssignment[];
    }>
  > => studentAPI.get("/dashboard", { params });

export const getStudentLessons = (params) => studentAPI.get("/lessons", { params })

export const getStudentAssignments = (params): Promise<ApiResponse<PaginatedResponse<IAssignment[]>>> => studentAPI.get("/assignments", { params })

export const getStudentAnnouncements = (params): Promise<ApiResponse<PaginatedResponse<IAnnouncement[]>>> => studentAPI.get("/announcements", { params })

export const getStudentClassSubjects = (params?): Promise<ApiResponse<ISubject[]>> => studentAPI.get("/class-subjects", { params })

export const getStudentClassSubjectsWithLessons = (params?): Promise<ApiResponse<(ISubject & { topLesson: ILesson })[]>> => studentAPI.get("/class-subjects-with-lessons", { params })
