import { EditIcon } from "@/assets/icons";
import { ReactNode, useState } from "react";
import { Button } from "./ui/button";

type Props = {
  viewComponent: ReactNode;
  editComponent: ReactNode;
};

const EditOrViewWrapper = ({ viewComponent, editComponent }: Props) => {
  const [editing, setEditing] = useState(false);

  return (
    <div className="grid">
      {!editing && (
        <Button
          variant="outline"
          className="items-center gap-2 ml-auto"
          onClick={() => setEditing(true)}
        >
          <EditIcon /> Edit
        </Button>
      )}
      {!editing ? viewComponent : editComponent}
    </div>
  );
};

export default EditOrViewWrapper;
