import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import {
  getFromSessionStorage,
  removeEmptyOrNull,
  removeFromSessionStorage,
  showErrorToast,
} from "@/lib/utils";
import { ApiResponse } from "@/types/api.types";

export const onReqFulfilled = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const token = getFromSessionStorage("token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  config.params = removeEmptyOrNull(config.params);

  return config;
};

export const onReqRejected = (error: AxiosError): Promise<never> => {
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  return Promise.reject(error);
};

export const onResFulfilled = <T>(
  response: AxiosResponse<ApiResponse<T>> | AxiosResponse<T>,
) => response.data;

export const onResRejected = (error: AxiosError): Promise<never> => {
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  if (["ERR_NETWORK", "ECONNABORTED", "ETIMEDOUT"].includes(error.code!)) {
    showErrorToast("Network error", {
      description: "Please check your connection and try again",
      closeButton: true,
    });

    return Promise.reject(
      (error.response as any)?.data.message || error.response,
    );
  }

  if (error?.response?.status === 401) {
    removeFromSessionStorage("token");
    removeFromSessionStorage("user");

    showErrorToast("Session expired", {
      description: "Please login again",
      closeButton: true,
    });

    return Promise.reject(
      (error.response as any)?.data.message || error.response,
    );
  }

  if (error?.response?.status === 403) {
    showErrorToast(
      (error.response as any)?.data.message || "Permission denied",
      {
        closeButton: true,
      },
    );
    return Promise.reject(
      (error.response as any)?.data.message || error.response,
    );
  }

  showErrorToast(
    (error.response as any)?.data.message || "Something went wrong",
    {
      closeButton: true,
    },
  );

  return Promise.reject(
    (error.response as any)?.data.message || error.response,
  );
};
