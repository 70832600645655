import { EmptyStateIcon } from "@/assets/icons";
import { IAssignment } from "@/types/assignments.types";
import { format } from "date-fns";
import AssignmentGroups from "../AssignmentGroups";
import AssignmentSubmitArea from "../AssignmentSubmitArea";
import EmptyState from "../EmptyState";
import sanitize from "sanitize-html";
import { sanitizeHtmlOptions } from "@/lib/utils";
import { MathJax } from "better-react-mathjax";

type LessonAssignmentsProps = {
  assignment: IAssignment;
  closeModal?: () => void;
};

const LessonAssignments = ({
  assignment,
  closeModal,
}: LessonAssignmentsProps) => {
  if (!assignment)
    return (
      <EmptyState icon={<EmptyStateIcon />}>
        <div className="max-w-[240px] font-semibold text-[#2C323C]">
          No assignment
        </div>
      </EmptyState>
    );

  return (
    <div className="grid">
      <p className="mb-2 ml-auto w-fit rounded-lg bg-primary-transparent p-[0.75rem] text-sm font-medium text-primary">
        Due Date: {format(new Date(assignment?.dueDate), "PPpp")}
      </p>
      <MathJax>
        <div
          className="flex flex-col gap-4"
          dangerouslySetInnerHTML={{
            __html: sanitize(assignment.content, sanitizeHtmlOptions),
          }}
        />
      </MathJax>
      <AssignmentGroups groups={assignment.groups} />
      <AssignmentSubmitArea assignment={assignment} closeModal={closeModal} />
    </div>
  );
};

export default LessonAssignments;
