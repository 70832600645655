import { updateSchoolDetails } from "@/api/admin";
import { CustomInput } from "@/components";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import useAuth from "@/hooks/useAuth";
import { cn, showSuccessToast } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import * as Yup from "yup"

const EditSchoolDetails = () => {
  const { school, setSchool } = useAuth();

  const initialValues = {
    name: school?.name ?? "",
    phoneNumber: school?.phoneNumber ?? "",
    email: school?.email ?? "",
    address: school?.address ?? "",
    city: school?.city ?? "",
    state: school?.state ?? "",
    website: school?.website ?? "",
    primaryColor: school?.primaryColor ?? "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    phoneNumber: Yup.string().required("This field is required"),
    email: Yup.string().email("Please provide a valid email").required("This field is required"),
    address: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    state: Yup.string().required("This field is required"),
    website: Yup.string().required("This field is required"),
    primaryColor: Yup.string().required("This field is required"),
  })

  const { mutate, isLoading } = useMutation(updateSchoolDetails, {
    onSuccess: ({ message, data }) => {
      showSuccessToast(message);
      setSchool(data)
    }
  })

  return (
    <Card className="mt-13 rounded-[20px] border-transparent px-4 py-5 md:px-5 md:py-6 lg:px-10 lg:py-11">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => mutate(values)} >
        {({ values, handleChange, handleSubmit }) => {
          return (
            <form className="grid gap-6 md:grid-cols-2" onSubmit={handleSubmit}>
              <CustomInput
                id="name"
                name="name"
                label="School name"
                onChange={handleChange}
                value={values.name}
                placeholder="Enter school name"
              />
              <CustomInput
                id="phone"
                name="phone"
                label="Phone Number"
                onChange={handleChange}
                value={values.phoneNumber}
              />
              <CustomInput
                id="email"
                name="email"
                label="Email"
                onChange={handleChange}
                value={values.email}
              />
              <CustomInput
                id="address"
                name="address"
                label="Address"
                onChange={handleChange}
                value={values.address}
              />
              <CustomInput
                id="city"
                name="city"
                label="City"
                onChange={handleChange}
                value={values.city}
              />
              <CustomInput
                id="state"
                name="state"
                label="State"
                onChange={handleChange}
                value={values.state}
              />
              <CustomInput
                id="website"
                name="website"
                label="Website"
                onChange={handleChange}
                value={values.website}
              />
              <div>
                <label className="mb-1 block text-sm font-medium text-[#495364]">
                  Color
                </label>
                <div className={cn("flex items-center gap-2 rounded-md border border-[#EAEBF0] text-sm has-[:focus]:border-primary has-[:disabled]:bg-[#FBFBFB]")}>
                  <input
                    type="color"
                    name="primaryColor"
                    value={values.primaryColor}
                    onChange={handleChange}
                    className={cn("text-bodyText w-full rounded-md h-11 !border-0 !outline-0 placeholder:text-[#a8b0bd] focus:![--tw-ring-shadow:transparent]")}
                  />
                </div>
              </div>
              <div className="flex h-full py-0.5 md:items-end">
                <Button className="h-10.5 w-[115px] rounded-lg" disabled={isLoading}>Update</Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default EditSchoolDetails;
