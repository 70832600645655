import { cn } from "@/lib/utils";

interface CustomCheckBoxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelClassName?: string;
}

const CustomCheckBox = ({
  label,
  id,
  type = "radio",
  labelClassName,
  ...props
}: CustomCheckBoxProps) => {
  return (
    <div className="flex gap-3">
      <input
        id={id}
        type={type}
        {...props}
        className={cn("accent-primary", props.className)}
      />
      <label
        htmlFor={id}
        className={cn(
          "cursor-pointer select-none text-sm font-medium text-[#495364]",
          labelClassName,
        )}
      >
        {label}
      </label>
    </div>
  );
};

export default CustomCheckBox;
