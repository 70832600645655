import { AttachmentIcon, CloseIcon, DocumentIcon } from "@/assets/icons";
import { Button } from "./ui/button";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { cn, removeItemAtIndex } from "@/lib/utils";
import { ErrorMessage, useFormikContext } from "formik";

type Props<CustomFormType> = {
  name: keyof CustomFormType;
  multiple?: boolean;
};

const Dropzone = <CustomFormType,>({
  name,
  multiple = false,
}: Props<CustomFormType>) => {
  const { setFieldValue, values } = useFormikContext<CustomFormType>();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles?.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) }),
      );
      setFieldValue(name as string, multiple ? files : files[0]);
    },
    [name, multiple, setFieldValue],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    multiple,
    ...(multiple && { maxFiles: 5 }),
    noClick: true,
    accept: {
      "text/*": [".csv", ".txt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  return (
    <div className="space-y-2">
      <div
        className={cn(
          "-z-[1] flex cursor-pointer flex-col items-center gap-4 rounded border border-dashed border-[#CBD5E1] px-4 py-6",
          isDragActive && "bg-gray-100",
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} aria-label="input-file" />
        <div className="flex flex-wrap justify-center gap-3">
          {multiple
            ? !!(values[name] as File[]).length &&
              (values[name] as File[])?.map((file, index) => (
                <FileItem
                  key={index}
                  file={file}
                  onDelete={() => {
                    setFieldValue(
                      name as string,
                      removeItemAtIndex(values[name] as File[], index),
                    );
                  }}
                />
              ))
            : !!values[name] && (
                <FileItem
                  file={values[name] as unknown as File}
                  onDelete={() => {
                    setFieldValue(name as string, null);
                  }}
                />
              )}
        </div>
        <DocumentIcon className="h-[30px] w-[30px] text-[#737D8F]" />
        <p className="max-w-[290px] text-center text-xs font-medium text-[#8F97A5]">
          {multiple
            ? "Drag and drop some files here, or click to select files. You can upload a maximum of 5 files"
            : "Drag and drop a file here, or click to select file. You can upload a maximum of 1 file"}
        </p>
        <Button type="button" variant="default" onClick={open}>
          Upload file{multiple && "(s)"}
        </Button>
      </div>
      <ErrorMessage
        className="text-xs text-destructive"
        component={"div"}
        name={name as string}
      />
    </div>
  );
};

const FileItem = ({ file, onDelete }: { file: File; onDelete: () => void }) => (
  <div className="text-bodyText z-50 flex items-center gap-1 rounded-full bg-[#EFEFEF] px-2 py-1 text-xs font-medium">
    <AttachmentIcon className="h-[15px] w-[15px]" />
    {file.name}
    <button
      className="flex items-center justify-center rounded-md p-[0.125rem] hover:bg-red-500 *:hover:text-white"
      type="button"
      onClick={(event) => {
        event.stopPropagation();
        onDelete();
      }}
    >
      <CloseIcon className="h-[12px] w-[12px]" />
    </button>
  </div>
);
export default Dropzone;
