import { createAssignment, updateAssignment } from "@/api/common";
import { Button } from "@/components/ui/button";
import { useMainLayoutContext } from "@/context/MainLayoutContext";
import { showSuccessToast } from "@/lib/toast";
import { removeEmptyOrNull } from "@/lib/utils";
import { IAssignment } from "@/types/assignments.types";
import { IClass } from "@/types/class.types";
import { ILesson } from "@/types/lesson.types";
import { ISubject } from "@/types/subject.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, formatISO, parseISO, sub } from "date-fns";
import { Formik } from "formik";
import * as Yup from "yup";
import SimilarFields from "./SimilarFields";

type CreateProps = {
  lesson?: ILesson;
  closeModal: () => void;
};
type EditProps = {
  assignment: IAssignment;
  closeModal: () => void;
};

const AddAssignment = ({ lesson, closeModal }: CreateProps) => {
  const queryClient = useQueryClient();
  const { currentTermId } = useMainLayoutContext();

  const initialValues = {
    lessonNote: lesson?._id ?? "",
    title: lesson?.topic ?? "",
    content: "",
    class: (lesson?.class as IClass)?._id ?? "",
    subject: (lesson?.subject as ISubject)?._id ?? "",
    term: currentTermId,
    canSubmitAfterDeadline: false,
    attachments: [],
    dueDate: new Date(),
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
    dueDate: Yup.date().min(
      sub(new Date(), { days: 1 }),
      "Due date cannot be before today",
    ),
  });

  const { mutate, isLoading } = useMutation(createAssignment, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.invalidateQueries({
        queryKey: ["lesson"],
      });
      queryClient.invalidateQueries({
        queryKey: ["assignment"],
      });
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ dueDate, ...rest }) =>
        mutate(
          removeEmptyOrNull({
            ...rest,
            dueDate: formatISO(dueDate),
            isGroup: false,
          }),
        )
      }
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="grid gap-4">
          <SimilarFields lesson={lesson} />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export const EditAssignment = ({ assignment, closeModal }: EditProps) => {
  const queryClient = useQueryClient();
  const { currentTermId } = useMainLayoutContext();

  const initialValues = {
    id: assignment?._id,
    title: assignment?.title ?? "",
    content: assignment?.content ?? "",
    class: (assignment?.class as IClass)?._id ?? "",
    subject: (assignment?.subject as ISubject)?._id ?? "",
    term: currentTermId,
    canSubmitAfterDeadline: assignment?.canSubmitAfterDeadline,
    attachments: [],
    dueDate: format(parseISO(assignment?.dueDate), "yyyy-MM-dd"),
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    class: Yup.string().required("This field is required"),
    subject: Yup.string().required("This field is required"),
    content: Yup.string(),
    attachments: Yup.array().max(
      5,
      "A maximum of 5 attachments can be uploaded",
    ),
    dueDate: Yup.date().min(
      sub(new Date(), { days: 1 }),
      "Due date cannot be before today",
    ),
  });

  const { mutate, isLoading } = useMutation(updateAssignment, {
    onSuccess: ({ message }) => {
      showSuccessToast(message);
      queryClient.invalidateQueries({
        queryKey: ["lesson"],
      });
      queryClient.invalidateQueries({
        queryKey: ["assignment"],
      });
      closeModal();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ dueDate, ...rest }) =>
        mutate(
          removeEmptyOrNull({
            ...rest,
            dueDate: formatISO(dueDate),
            isGroup: false,
          }) as any,
        )
      }
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="grid gap-4">
          <SimilarFields />
          <div className="flex items-center gap-3 ml-auto">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              className="min-w-[100px]"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddAssignment;
