import { EmptyStateIcon } from "@/assets/icons";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

type Props = {
  icon?: ReactNode;
  children: ReactNode;
  className?: string;
};

const EmptyState = ({
  icon = <EmptyStateIcon />,
  children,
  className,
}: Props) => {
  return (
    <div
      className={cn("flex min-h-[50vh] items-center justify-center", className)}
    >
      <div className="mx-auto grid max-w-[450px] justify-center *:mx-auto *:mb-5">
        {icon}
        {children}
      </div>
    </div>
  );
};

export default EmptyState;
