import { useLocation } from "react-router-dom";

export type Layout = "admin" | "teacher" | "student";

export const useLayout = () => {
  const { pathname } = useLocation();

  return pathname.startsWith("/teacher")
    ? "teacher"
    : pathname.startsWith("/admin")
      ? "admin"
      : "student";
};
