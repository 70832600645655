import { DocumentText } from "@/assets/icons";

type Props = {};

const NoteTag = (props: Props) => {
  return (
    <div className="flex items-center gap-1">
      <DocumentText className="h-[16px] w-[16px] text-[#737D8F]" />
      <p className="text-xs font-medium text-[#737D8F]">2 mins</p>
    </div>
  );
};

export default NoteTag;
