import { IAllocation } from "@/types/allocations.types";
import { ISubject } from "@/types/subject.types";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQueries } from "@tanstack/react-query";
import { getClasses, getSubjects, getTerms } from "@/api/common";
import { getSubjectAllocations } from "@/api/teachers";
import { useLayout } from "@/hooks/useLayout";
import { ITerms } from "@/types/terms.types";
import { getFromSessionStorage, setToSessionStorage } from "@/lib/storage";
import { IClass } from "@/types/class.types";
import useAuth from "@/hooks/useAuth";
import { getStudentClassSubjects } from "@/api/students";

type TMainLayoutContext = {
  subjects?: ISubject[];
  classes?: IClass[];
  allocations?: IAllocation[];
  terms?: ITerms[];
  currentTermId: string | undefined;
  setCurrentTermId: Dispatch<string | undefined>;
};

export const MainLayoutContext = createContext<TMainLayoutContext | undefined>(
  undefined,
);

const MainLayoutContextProvider = ({ children }: PropsWithChildren) => {
  const layout = useLayout();
  const { isAuthenticated } = useAuth();

  const [
    { data: rawSubjects },
    { data: rawClasses },
    { data: rawAllocations },
    { data: rawTerms },
  ] = useQueries({
    queries: [
      {
        queryKey: ["subjects"],
        queryFn: () =>
          layout === "student"
            ? getStudentClassSubjects()
            : getSubjects({ paginate: "false" }),
        suspense: true,
        enabled: isAuthenticated && layout !== "teacher",
      },
      {
        queryKey: ["classes"],
        queryFn: () => getClasses({ paginate: "false" }),
        suspense: true,
        enabled: isAuthenticated && layout === "admin",
      },

      {
        queryKey: ["subject-allocations"],
        queryFn: getSubjectAllocations,
        suspense: true,
        enabled: isAuthenticated && layout === "teacher",
      },
      {
        queryKey: ["terms"],
        queryFn: getTerms,
        suspense: true,
        enabled: isAuthenticated,
      },
    ],
  });

  const subjects = rawSubjects?.data;
  const classes = rawClasses?.data;
  const allocations = rawAllocations?.data?.docs;
  const terms = rawTerms?.data;
  const [currentTermId, setCurrentTermId] = useState(
    getFromSessionStorage("currentTermId") ?? terms?.at(-1)?._id,
  );

  useEffect(() => {
    setToSessionStorage("currentTermId", currentTermId);
  }, [currentTermId]);

  return (
    <MainLayoutContext.Provider
      value={{
        subjects: subjects ?? [],
        classes: classes ?? [],
        allocations: allocations ?? [],
        terms: terms ?? [],
        currentTermId,
        setCurrentTermId,
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayoutContext = () => {
  const context = useContext(MainLayoutContext);

  if (!context)
    throw new Error("useAuth must be used within an AuthContextProvider");

  return context;
};

export default MainLayoutContextProvider;
